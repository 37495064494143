<template>
  <v-container class="container--fluid grid-list-md">
    <DocumentNumberConfirmation :confirmDocumentNumberDialog.sync="confirmDocumentNumberDialog" />
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('invoice.invoiceActions') }}</span>
        <NextAndPreviousCommon :nextId="nextId" :prevId="prevId" :documentId="invoiceId" @idChange="redirectOnNextAndPrevious" />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="(invoiceId && !!model.is_locked && model.user_id!==user.id)">
      <v-col cols="12" class="my-3">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1 error--text" >*{{ $t('common.documentReadOnlyModeNotice',{userId:model.user.name}) }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <v-card-title class="py-1">
            <h5 v-if="originModel.invoice_document_number" style="max-width: 175px;" :class="invoiceId ? 'px-1 text-truncate font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ invoiceId && !originModel.title ? `${originModel.invoice_document_number}.` : $t("invoice.addInvoice") || invoiceId ? originModel.title && `${originModel.invoice_document_number}. ${originModel.title}` : $t('invoice.addInvoice') }}</h5>
            <v-spacer />
            <v-menu bottom v-if="invoiceId" :close-on-click="true" :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="remarksList && remarksList.length > 0 ? 'success' : 'primary'"
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn color="primary" class=" ma-2" small outlined v-bind="attrs" v-on="on" :disabled="!valid || (!!model.is_locked && model.user_id!==user.id)">
                  <v-icon left>
                    mdi-menu-down
                  </v-icon>
                  {{$t('quotation.options')}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="accessRight.includes('create')" :disabled="!invoiceItems[0].invoice_id" dense class="primary--text" @click="createReceipt">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-receipt-outline
                      </v-icon>
                      {{$t('invoice.createReceipt')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="accessRight.includes('create')" :disabled="!invoiceItems[0].invoice_id" dense class="primary--text" @click="creditInvoiceConfirmationDialogBox">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-cash-plus
                      </v-icon>
                      {{$t('creditInvoice.creditInvoice')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="accessRight.includes('create')" dense class="primary--text" @click="copyInvoice">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-content-copy
                      </v-icon>
                      {{$t('common.copyDocument')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="actDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-pulse
                      </v-icon>
                      {{$t('quotation.activityLog')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="attachedDocumentsDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{$t('employee.attachedDocuments')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu bottom v-if="!invoiceId" :close-on-click="true" :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class=" ma-2" small outlined v-bind="attrs" v-on="on">
                  <v-icon left>
                    mdi-menu-down
                  </v-icon>
                  {{ $t('invoice.createFromMultiple') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item dense class="primary--text" @click="multipleProformaDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document-alert-outline
                      </v-icon>
                      {{$t('proforma.proforma')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="multipleOrderDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-note-multiple-outline
                      </v-icon>
                      {{ $t('order.order') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="multipleDeliveryDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-account-badge-outline
                      </v-icon>
                      {{$t('delivery.delivery')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="invoiceId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("calendar.calendarEvents") }}
                </v-btn>
              </template>
              <v-list>
                <LoginGoogle />
                <CommonCalendar :docId="invoiceId" docType="Invoice" :model="model" />
              </v-list>
            </v-menu>
            <HtmlToPdf v-if="invoiceId" class="me-2 mb-1" :disabled="valid && !(!!model.is_locked && model.user_id!==user.id)" :model="originModel" type="InvoicePDF"/>
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="!valid || (!!model.is_locked && model.user_id!==user.id) || isFieldReadable && !originModel.accounting_update && !!(invoiceId && !originModel.customer.book_kiping_no) || (isFieldReadable && !saveOnlyCategoryStatus) && isFieldReadable && !!originModel.accounting_update && !!(invoiceId && originModel.customer.book_kiping_no) || isFieldReadable && !!originModel.accounting_update && !!(invoiceId && !originModel.customer.book_kiping_no) || (accessRight.length < 2 && accessRight.includes('show'))"
              @click="saveInvoice"
            >
              {{ $t('invoice.save') }}
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton v-if="(invoiceId && Object.keys(invoiceById).length <= 0) || (copiedInvoice && copyFormLoading)"/>
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="6">
                  <v-card
                    class="mx-auto"
                    flat
                  >
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">{{$t('invoice.customerDetail')}}</h6>
                      <v-spacer />
                      <v-icon v-if="customer_id" @click="openCustomerAdditionalDetailModel" color="primary" class="float-end">mdi-eye-outline</v-icon>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-combobox
                            class="asterisk"
                            autocomplete="new-password"
                            dense
                            :readonly="isMultiple || originModel.delivery_note_id || isFieldReadable ? true : false"
                            cache-items
                            v-model="selectedCustomer"
                            :items="customerList.data"
                            item-text="name_1"
                            item-value="id"
                            @change="onCustomerChange"
                            flat
                            :rules="customerValid"
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @focus="documentNextAndPrevious(originModel.customer_id)"
                            @blur="documentNextAndPreviousBlur()"
                            @dblclick="redirectOnEditCustomerPage"
                            :search-input.sync="customerSearch"
                            :label="$t('invoice.field.customer')"
                          >
                            <template v-slot:append-outer>
                              <v-icon color="primary" dense v-if="!originModel.delivery_note_id && !isFieldReadable && !isMultiple" @click="customerDialog = true">
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span @dblclick="redirectOnEditCustomerPage">{{ `${data.item.id}. ${data.item.name_1}` }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div v-show="hasNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="asterisk"
                            autocomplete="new-password"
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.customer_name"
                            :label="$t('invoice.field.customerName')"
                            :rules="customerNameValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.address"
                            :label="$t('invoice.field.address')"
                            :rules="[lessThanValidation($t('invoice.field.address'), $t('common.less'), 250, model.address)]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.town"
                            :label="$t('invoice.field.town')"
                            :rules="[lessThanValidation($t('invoice.field.town'), $t('common.less'), 100, model.town)]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card
                    class="mx-auto"
                    flat
                  >
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-file-delimited-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">{{$t('invoice.invoiceDetail')}}</h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="8">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            dense
                            v-model="model.title"
                            :label="$t('invoice.field.title')"
                            :rules="titleValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            dense
                            hide-details="auto"
                            v-model="model.invoice_year"
                            :rules="invoiceYearValid"
                            required
                            :label="$t('invoice.field.year')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.customer_order_no"
                            :label="$t('invoice.field.customerOrderNo')"
                            :rules="customerOrderNoValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.invoice_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="formattedInvoiceDate"
                                :label="$t('invoice.field.invoiceDate')"
                                @input="onInvoiceDateChange"
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.invoice_date"
                              @input="onInvoiceDateChange"
                              :min="minDate"
                              :max="maxDate"
                              @change="$refs.startDateMenu.save(model.invoice_date)"
                            >
                              <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="formattedInvoiceDate"
                            :label="$t('invoice.field.invoiceDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="deliveryDateMenu"
                            v-model="deliveryDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.delivery_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            nudge-left="120px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="formattedDeliveryDate"
                                :label="$t('invoice.field.deliveryDate')"
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              />
                            </template>
                            <v-date-picker
                              elevation="240"
                              :min="model.invoice_date ? model.invoice_date : ''"
                              v-model="model.delivery_date"
                              @change="$refs.deliveryDateMenu.save(model.delivery_date)"
                            >
                              <v-btn small class="primary" @click="$refs.deliveryDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="formattedDeliveryDate"
                            :label="$t('invoice.field.deliveryDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            ref="contactCombo"
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedContact"
                            :items="contactList.data"
                            item-text="name"
                            item-value="id"
                            @change="onContactChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            :search-input.sync="contactSearch"
                            :label="$t('invoice.field.contact')"
                          >
                            <template v-slot:append-outer>
                              <v-icon class="me-1 mt-1" color="primary" dense v-if="!isFieldReadable" @click="contactDialog = true">
                                mdi-magnify
                              </v-icon>
                              <v-icon class="mt-1" color="primary" dense v-if="accessRight.includes('create') && customer_id && !isFieldReadable" @click="dialog = true">
                                mdi-plus
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div v-show="hasContactNextPage" v-intersect="infiniteScrollOnContact" ref="load" class="loader text-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            @change="onEmployeeChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @dblclick="redirectOnEditEmployeePage"
                            :search-input.sync="employeeSearch"
                            :label="$t('invoice.field.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon class="mt-1" color="primary" dense v-if="!isFieldReadable" @click="employeeDialog = true">
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div v-show="hasEmployeeNextPage" v-intersect="infiniteScrollOnEmployee" ref="load" class="loader text-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.delivery_company"
                            :label="$t('invoice.field.deliveryCompany')"
                            :rules="[lessThanValidation($t('invoice.field.deliveryCompany'), $t('common.less'), 25, model.delivery_company)]"
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row :dense="true">
                        <v-col cols="4" v-if="moreDocumentNumber && !invoiceId">
                          <v-autocomplete
                            :disabled="isInvoiceId"
                            hide-details="auto"
                            cache-items
                            :items="documentData"
                            :key="documentData.length"
                            item-text="from_no"
                            item-value="id"
                            flat
                            :rules="!isInvoiceId ? documentNumberRequired : []"
                            v-model="model.doc_series_number"
                            return-object
                            hide-no-data
                            required
                            single-line
                            :label="$t('documentSeries.selectNumber')"
                          >
                            <template v-slot:item="data">
                              <span>{{`${data.item.series} - ${data.item.from_no}. ${data.item.description}`}}</span>
                            </template>
                            <template v-slot:selection="data">
                              <span>{{`${data.item.series} - ${data.item.from_no}. ${data.item.description}`}}</span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <WarehouseInput :isFieldReadable="isFieldReadable" :selectedWarehouseModel.sync="selectedWarehouse" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card
                    class="mx-auto"
                    flat
                  >
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="8" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6 class="ms-2 align-self-center font-weight-regular">{{$t('invoice.listOfItems')}}</h6>
                        </v-col>
                        <v-col cols="4" class="text-end font-weight-regular">
                          <SuppliedQuantityDialog :id="invoiceId" @suppliedQty="suppliedQty" />
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{width: '20px'}" v-if="!fromMultipleDelivery && !fromMultipleProformaInvoice && fromOrder && originModel.order_id && !$route.params.invoice_id || !fromMultipleDelivery && !fromMultipleProformaInvoice && originModel.proforma_invoice_id && !$route.params.invoice_id">
                                    <v-checkbox :readonly="isFieldReadable" x-small @click="!isFieldReadable ? selectAllOrderProduct() : null" v-model="allSelectedProduct" dense hide-details />
                                  </th>
                                  <th :style="{width: '40px'}">
                                    {{ $t('invoice.no') }}
                                  </th>
                                  <th :style="{width: '220px'}">
                                    {{ $t('invoice.product') }}
                                  </th>
                                  <th :style="{width: '180px'}">
                                    {{ $t('invoice.itemDescription') }}
                                  </th>
                                  <th :style="{width: '50px'}">
                                    {{ $t('invoice.quantity') }}
                                  </th>
                                  <th v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))" class="text-center" :style="{width: '100px'}">
                                    {{ $t('invoice.unitPrice') }}
                                  </th>
                                  <th v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))" :style="{width: '50px'}">
                                    {{ $t('invoice.lineDiscount') }}
                                  </th>
                                  <th v-if="!invoiceId" class="text-center" :style="{width: '100px'}">
                                    <span v-if="($route.query.orderId && orderById.including_vat) || ($route.query.proformaId && proformaById.including_vat) || ($route.query.deliveryId && deliveryById.including_vat) || ($route.query.quotationId && quotationById.including_vat) || ($route.query.receiptTaxInvoiceId && receiptTaxInvoiceById.including_vat) || ($route.query.creditInvoiceId && creditInvoiceById.including_vat) || ($route.query.returnCertificateId && returnCertificateById.including_vat)">
                                      {{$t("company.field.totalIncludingVAT")}}
                                    </span>
                                    <span v-else-if="($route.query.orderId && !orderById.including_vat) || ($route.query.proformaId && !proformaById.including_vat) || ($route.query.deliveryId && !deliveryById.including_vat) || ($route.query.quotationId && !quotationById.including_vat) || ($route.query.receiptTaxInvoiceId && !receiptTaxInvoiceById.including_vat) || ($route.query.creditInvoiceId && !creditInvoiceById.including_vat) || ($route.query.returnCertificateId && !returnCertificateById.including_vat)">
                                      {{$t('invoice.total')}}
                                    </span>
                                    <span v-else-if="($route.params.copiedModel && invoiceById.including_vat)">
                                      {{$t("company.field.totalIncludingVAT")}}
                                    </span>
                                    <span v-else-if="($route.params.copiedModel && !invoiceById.including_vat)">
                                      {{$t("invoice.total")}}
                                    </span>
                                    <span v-else-if="!profileById.invoice">
                                      {{$t('invoice.total')}}
                                    </span>
                                    <span v-else-if="profileById.invoice">
                                      {{$t("company.field.totalIncludingVAT")}}
                                    </span>
                                  </th>
                                  <th v-else class="text-center" :style="{width: '100px'}">
                                    {{ !model.including_vat ? $t('invoice.total') : $t("company.field.totalIncludingVAT") }}
                                  </th>
                                  <th class="text-center" :style="{width: '80px'}">
                                    {{ $t('invoice.action') }}
                                  </th>
                                </tr>
                              </thead>
                              <!-- // invoice from multiple order  -->
                              <tbody v-if="isMultiple && !fromMultipleDelivery && !fromMultipleProformaInvoice">
                                <template v-for="(orderItem, indexItem) in invoiceItems">
                                  <tr class="accent lighten-3" style="vertical-align: bottom" :key="indexItem">
                                    <td class="text-center primary--text font-weight-bold" colspan="8">
                                      {{ $t('invoice.field.orderNo') }} {{ orderItem.order_id }} {{ orderItem.title ? '/ ' + orderItem.title : null }}
                                    </td>
                                  </tr>
                                  <tr style="vertical-align: bottom" v-for="(item, index) in orderItem.invoiceItems" :key="orderItem.order_id+'-'+index" :class="index+1 == orderItem.invoiceItems.length ? 'newRow_'+orderItem.order_id+index : ''">
                                    <td>
                                      {{index+1}}
                                    </td>
                                    <td>
                                      <span v-if="isFieldReadable || fromDelivery || originModel.delivery_note_id" @dblclick="redirectOnEditProductPage(item.item_id)">
                                        {{item.item_code }} 
                                      </span>
                                      <span v-else class="d-flex">
                                        <product-item-select :selectedCustomer="selectedCustomer" :table="'Invoice'" :key="index" :itemId="item.item_id" :item="item" :index="index" :indexItem="indexItem" @onProductChange="onProductChange" />
                                        <template>
                                          <v-icon class="mt-1" color="primary" dense @click="productDialog=true, productDialogIndex=index, mainDocIndexForMultiple=indexItem" :disabled="(accessRight.length < 2 && accessRight.includes('show'))">
                                            mdi-magnify
                                          </v-icon>
                                        </template>
                                      </span>
                                    </td>
                                    <td>
                                      <v-textarea
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.item_description"
                                        :title="item.item_description"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="[lessThanValidation($t('invoice.itemDescription'), $t('common.less'), 4000, item.item_description)]"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.quantity"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="quantityValid"
                                        required
                                        @input="itemPriceCalculation(index, indexItem)"
                                      >
                                      <template v-if="item.quantity === 0">
                                        {{item.quantity = null}}
                                      </template>
                                    </v-text-field>
                                    </td>
                                    <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))">
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'unit_price'+index"
                                        :ref="'unit_price'+indexItem+index"
                                        :reverse="locale === 'he' ? false : true"
                                        hide-details="auto"
                                        v-model="item.unit_price"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="item.item_code === '*' ? unitPriceValid : unitPriceValidInvoice"
                                        required
                                        @input="itemPriceCalculation(index, indexItem)"
                                      >
                                      <template v-if="item.unit_price === 0">
                                        {{item.unit_price = null}}
                                      </template>
                                    </v-text-field>
                                    </td>
                                    <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))">
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.line_discount"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="lineDiscountValid"
                                        required
                                        @input="itemPriceCalculation(index, indexItem)"
                                      >
                                        <template v-slot:append>
                                          <v-icon class="mt-2" x-small color="primary">mdi-percent-outline</v-icon>
                                        </template>
                                        <template v-if="item.line_discount === 0">
                                        {{item.line_discount = null}}
                                      </template>
                                      </v-text-field>
                                    </td>
                                    <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))" :class="locale === 'he' ? 'text-start' : 'text-end'">
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'total_price' + index"
                                        :ref="'total_price'+indexItem+index"
                                        :reverse="locale === 'he' ? false : true"
                                        hide-details="auto"
                                        v-model="item.total"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="item.item_code === '*' ? unitPriceValid : unitPriceValidInvoice"
                                        required
                                        @input="itemPriceCalculation(index, indexItem)"
                                      >
                                      <template v-if="item.total === 0">
                                        {{item.total = null}}
                                      </template>
                                    </v-text-field>
                                    </td>
                                    <td class="text-center">
                                      <v-row>
                                        <v-col cols="6" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="isFieldReadable"
                                            color="primary"
                                            small
                                            @click="addRowForMultiple(indexItem, 'newRow_'+orderItem.order_id+(orderItem.invoiceItems.length-1))"
                                          >
                                            <v-icon color="primary">
                                              mdi-plus
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="6" class="text-start">
                                          <v-btn
                                            v-if="orderItem.invoiceItems.length !== 1"
                                            icon
                                            :disabled="isFieldReadable"
                                            color="error"
                                            small
                                            @click="deleteRowForMultiple(indexItem, index)"
                                          >
                                            <v-icon color="error">
                                              mdi-delete-sweep-outline
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                              <!-- // invoice from delivery and proforma -->
                              <tbody v-if="fromMultipleDelivery || fromMultipleProformaInvoice">
                                <template v-for="(orderItem, indexItem) in invoiceItems">
                                  <tr class="accent lighten-3" style="vertical-align: bottom" :key="indexItem" v-if="fromMultipleProformaInvoice">
                                    <td class="text-center primary--text font-weight-bold" colspan="9">
                                      {{ $t('invoice.field.proformaNo') }} {{ orderItem.proforma_invoice_id }} {{ orderItem.title ? '/ ' + orderItem.title : null }}
                                    </td>
                                  </tr>
                                  <tr class="accent lighten-3" style="vertical-align: bottom" :key="indexItem" v-else-if="fromMultipleDelivery">
                                    <td class="text-center primary--text font-weight-bold" colspan="9">
                                      {{ $t('invoice.field.deliveryNo') }} {{ orderItem.delivery_note_id }} {{ orderItem.title ? '/ ' + orderItem.title : null }}
                                    </td>
                                  </tr>
                                  <tr style="vertical-align: bottom" v-for="(item, index) in orderItem.invoiceItems" :key="fromMultipleProformaInvoice ? orderItem.proforma_invoice_id+'-'+index : orderItem.delivery_note_id+'-'+index" :class="fromMultipleProformaInvoice ? (index+1 == orderItem.invoiceItems.length ? 'newRow_'+orderItem.proforma_invoice_id+index : '') : (index+1 == orderItem.invoiceItems.length ? 'newRow_'+orderItem.delivery_note_id+index : '')">
                                    <td>
                                      {{index+1}}
                                    </td>
                                    <td>
                                      <span v-if="isFieldReadable" @dblclick="redirectOnEditProductPage(item.item_id)">
                                        {{item.item_code }}
                                      </span>
                                      <span v-else class="d-flex">
                                        <product-item-select :selectedCustomer="selectedCustomer" :table="'Invoice'" :key="index" :itemId="item.item_id" :item="item" :index="index" :indexItem="indexItem" @onProductChange="onProductChange" />
                                        <template>
                                          <v-icon class="mt-1" color="primary" dense @click="productDialog=true, productDialogIndex=index, mainDocIndexForMultiple=indexItem" :disabled="(accessRight.length < 2 && accessRight.includes('show'))">
                                            mdi-magnify
                                          </v-icon>
                                        </template>
                                      </span>
                                    </td>
                                    <td>
                                      <v-textarea
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.item_description"
                                        :title="item.item_description"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="[lessThanValidation($t('invoice.itemDescription'), $t('common.less'), 4000, item.item_description)]"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.quantity"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="quantityValid"
                                        required
                                        @input="itemPriceCalculation(index,indexItem)"
                                      >
                                        <template v-if="item.quantity === 0">
                                          {{item.quantity = null}}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))">
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'unit_price'+index"
                                        :ref="'unit_price'+indexItem+index"
                                        :reverse="locale === 'he' ? false : true"
                                        hide-details="auto"
                                        v-model="item.unit_price"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="item.item_code === '*' ? unitPriceValid : unitPriceValidInvoice"
                                        required
                                        @input="itemPriceCalculation(index,indexItem)"
                                      >
                                        <template v-if="item.unit_price === 0">
                                          {{item.unit_price = null}}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))">
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.line_discount"
                                        :disabled="!item.selectedProduct ? true : false"
                                        :rules="lineDiscountValid"
                                        required
                                        @input="itemPriceCalculation(index,indexItem)"
                                      >
                                        <template v-slot:append>
                                          <v-icon class="mt-2" x-small color="primary">mdi-percent-outline</v-icon>
                                        </template>
                                        <template v-if="item.line_discount === 0">
                                          {{item.line_discount = null}}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))" :class="locale === 'he' ? 'text-start' : 'text-end'">
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'total_price' + index"
                                        :ref="'total_price' + indexItem+index"
                                        :reverse="locale === 'he' ? false : true"
                                        hide-details="auto"
                                        v-model="item.total"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="item.item_code === '*' ? unitPriceValid : unitPriceValidInvoice"
                                        required
                                        @input="itemPriceCalculation(index,indexItem)"
                                      >
                                        <template v-if="item.total === 0">
                                          {{item.total = null}}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td class="text-center" v-if="fromMultipleProformaInvoice">
                                      <v-row>
                                        <v-col cols="6" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="isFieldReadable"
                                            color="primary"
                                            small
                                            @click="addRowForMultiple(indexItem, 'newRow_'+orderItem.proforma_invoice_id+(orderItem.invoiceItems.length-1))"
                                          >
                                            <v-icon color="primary">
                                              mdi-plus
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="6" class="text-start">
                                          <v-btn
                                            v-if="orderItem.invoiceItems.length !== 1"
                                            icon
                                            :disabled="isFieldReadable"
                                            color="error"
                                            small
                                            @click="deleteRowForMultiple(indexItem, index)"
                                          >
                                            <v-icon color="error">
                                              mdi-delete-sweep-outline
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </td>
                                    <td class="text-center" v-if="fromMultipleDelivery">
                                      <v-row>
                                        <v-col cols="6" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="isFieldReadable"
                                            color="primary"
                                            small
                                            @click="addRowForMultiple(indexItem, 'newRow_'+orderItem.delivery_note_id+(orderItem.invoiceItems.length-1))"
                                          >
                                            <v-icon color="primary">
                                              mdi-plus
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="6" class="text-start">
                                          <v-btn
                                            v-if="orderItem.invoiceItems.length !== 1"
                                            icon
                                            :disabled="isFieldReadable"
                                            color="error"
                                            small
                                            @click="deleteRowForMultiple(indexItem, index)"
                                          >
                                            <v-icon color="error">
                                              mdi-delete-sweep-outline
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                              <tbody v-if="!isMultiple && !fromMultipleDelivery && !fromMultipleProformaInvoice">
                                <template v-for="(item, index) in invoiceItems">
                                  <template v-if="index === 0 || item.same_doc_id !== invoiceItems[index - 1].same_doc_id && item.item_code !== '*'">
                                    <tr v-if="item.source_document_number" :key="'new_line_' + item.same_doc_id" :style='"background-color: #CFD8DC"'>
                                      <td :colspan="($route.query.orderId || $route.query.proformaId) && !$route.query.type ? 2 : 1"></td>
                                      <td><span>-</span></td>
                                      <td colspan="6">
                                        <span>
                                          <b>{{$t('order.field.docNo')}}:</b> {{item.source_document_number}}<br />
                                        </span>
                                        <span>
                                          <b>{{$t('order.field.docDate')}}:</b> {{item.source_document_date.split('T')[0]}}<br />
                                        </span>
                                        <span v-if="item.source_document_title">
                                          <b>{{$t('order.field.docTitle')}}:</b> {{item.source_document_title}}<br />
                                        </span>
                                        <span v-if="item.source_document_customer_order_no">
                                          <b>{{$t('order.field.customerOrderNo')}}:</b> {{item.source_document_customer_order_no}}<br />
                                        </span>
                                      </td>
                                    </tr>
                                  </template>
                                <tr style="vertical-align: bottom" :key="index" :class="['yourRowClass', index + 1 === invoiceItems.length ? 'newRow' : '', {'disabled': (fromOrder || fromDelivery || $route.query.proformaId) && (!$route.query.type) && !model.id}, item.item_code === '*' && item.doc_discount ? 'yellow accent-2' : '', {'disabled': item.item_code === '*' && item.doc_discount}]">
                                  <td v-if="fromOrder && originModel.order_id && item.selectedProduct && !$route.params.invoice_id || originModel.proforma_invoice_id && item.selectedProduct && !$route.params.invoice_id">
                                    <v-checkbox :readonly="isFieldReadable" x-small @click="!isFieldReadable ? selectedInvoiceProduct() : ''" v-model="selectedProduct" :value="item" @change="checkBoxEventCalculate(item)" dense hide-details />
                                  </td>
                                  <td v-else-if="fromOrder && originModel.order_id && !$route.params.invoice_id || originModel.proforma_invoice_id && !$route.params.invoice_id"></td>
                                  <td>
                                    {{index+1}}
                                  </td>
                                  <td>
                                    <span v-if="isFieldReadable" @dblclick="redirectOnEditProductPage(item.item_id)">
                                      {{item.item_code }}
                                    </span>
                                    <span v-else class="d-flex">
                                      <product-item-select :selectedCustomer="selectedCustomer" :table="'Invoice'" :key="index" :itemId="item.item_id" :item="item" :index="index" @onProductChange="onProductChange" />
                                      <template>
                                        <v-icon class="mt-1" color="primary" dense @click="productDialog=true, productDialogIndex=index" :disabled="(accessRight.length < 2 && accessRight.includes('show'))">
                                          mdi-magnify
                                        </v-icon>
                                      </template>
                                    </span>
                                  </td>
                                  <td>
                                    <v-textarea
                                      v-if="!item.doc_discount"
                                      dense
                                      rows="1"
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.item_description"
                                      :title="item.item_description"
                                      :disabled="!item.selectedProduct ? true : false"
                                      :rules="[lessThanValidation($t('invoice.itemDescription'), $t('common.less'), 4000, item.item_description)]"
                                      required
                                    />
                                    <v-textarea 
                                      v-else
                                      dense
                                      rows="1"
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      :placeholder="$t('common.documentDiscount')"
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.quantity"
                                      :disabled="!item.selectedProduct ? true : false"
                                      :rules="quantityValid"
                                      required
                                      @input="itemPriceCalculation(index)"
                                    >
                                      <template v-if="item.quantity === 0">
                                        {{item.quantity = null}}
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))">
                                    <v-text-field
                                      dense
                                      :readonly="isFieldReadable"
                                      :name="'unit_price'+index"
                                      :ref="'unit_price'+index"
                                      :reverse="locale === 'he' ? false : true"
                                      hide-details="auto"
                                      v-model="item.unit_price"
                                      :disabled="!item.selectedProduct ? true : false"
                                      :rules="item.item_code === '*' ? unitPriceValid : unitPriceValidInvoice"
                                      required
                                      @input="itemPriceCalculation(index)"
                                    >
                                      <template v-if="item.unit_price === 0">
                                        {{item.unit_price = null}}
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))">
                                    <v-text-field
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.line_discount"
                                      :disabled="!item.selectedProduct ? true : false"
                                      :rules="lineDiscountValid"
                                      required
                                      @input="itemPriceCalculation(index)"
                                    >
                                      <template v-slot:append>
                                        <v-icon class="mt-2" x-small color="primary">mdi-percent-outline</v-icon>
                                      </template>
                                      <template v-if="item.line_discount === 0">
                                        {{item.line_discount = null}}
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <td v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))" :class="locale === 'he' ? 'text-start' : 'text-end'">
                                    <v-text-field
                                      dense
                                      :readonly="isFieldReadable"
                                      :name="'total_price' + index"
                                      :ref="'total_price' + index"
                                      :reverse="locale === 'he' ? false : true"
                                      hide-details="auto"
                                      v-model="item.total"
                                      :disabled="
                                        !item.selectedProduct ? true : false
                                      "
                                      :rules="item.item_code === '*' ? unitPriceValid : unitPriceValidInvoice"
                                      required
                                      @input="itemPriceCalculation(index)"
                                    >
                                      <template v-if="item.total === 0">
                                        {{item.total = null}}
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="4" class="text-end">
                                        <v-btn
                                          icon
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
                                          color="primary"
                                          small
                                          @click="addRow"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="4" class="text-end">	
                                        <v-btn	
                                          icon	
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show')) || !item.selectedProduct"	
                                          color="primary"	
                                          x-small	
                                          class="mt-1"	
                                          @click="copyData(index)"	
                                        >	
                                          <v-icon color="primary">	
                                            mdi-content-copy	
                                          </v-icon>	
                                        </v-btn>	
                                      </v-col>
                                      <v-col cols="4" class="text-start">
                                        <v-btn
                                          icon
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
                                          color="error"
                                          small
                                          @click="deleteRow(index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="4">
                                  <v-autocomplete
                                    dense
                                    :class="relatedQuotationItems.length > 0 ? 'accent lighten-3' : ''"
                                    cache-items
                                    :items="relatedQuotationItems"
                                    item-text="related_model_id"
                                    item-value="related_model_id"
                                    @change="redirectOnEditQuotationPage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('invoice.field.quotationNo')"
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-autocomplete
                                    dense
                                    :class="relatedOrderItems.length > 0 ? 'accent lighten-3' : ''"
                                    cache-items
                                    :items="relatedOrderItems"
                                    item-text="related_model_id"
                                    item-value="related_model_id"
                                    @change="redirectOnEditOrderPage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('quotation.field.orderNo')"
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field
                                    :readonly="isFieldReadable"
                                    hide-details="auto"
                                    dense
                                    v-model="model.car_no"
                                    :label="$t('invoice.field.carNo')"
                                    :rules="carnoValid"
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-autocomplete
                                    dense
                                    :class="relatedProformaInvoiceItems.length > 0 ? 'accent lighten-3' : ''"
                                    cache-items
                                    :items="relatedProformaInvoiceItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="redirectOnEditProformaPage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('proformaPdf.customerInvoiceNo')"
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-menu
                                    v-if="!isFieldReadable"
                                    ref="paymentDateMenu"
                                    v-model="paymentDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="model.payment_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        hide-details="auto"
                                        dense
                                        v-model="formattedPaymentDate"
                                        :label="$t('invoice.field.paymentDate')"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="model.payment_date"
                                      @change="$refs.paymentDateMenu.save(model.payment_date)"
                                    >
                                      <v-btn small class="primary" @click="$refs.paymentDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                  <v-text-field
                                    v-else
                                    dense
                                    hide-details="auto"
                                    v-model="formattedPaymentDate"
                                    :label="$t('invoice.field.paymentDate')"
                                    readonly
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-autocomplete
                                    :class="relatedDeliveryItems.length > 0 ? 'accent lighten-3' : ''"
                                    hide-details="auto"
                                    dense
                                    cache-items
                                    :items="relatedDeliveryItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    flat
                                    return-object
                                    hide-no-data
                                    required
                                    single-line
                                    :label="$t('order.field.deliveryNo')"
                                    @change="redirectOnEditDeliveryPage"
                                  />
                                </v-col>
                                <v-col v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))" cols="4">
                                  <v-text-field
                                    hide-details="auto"
                                    readonly
                                    dense
                                    v-model="model.balance"
                                    :label="$t('invoice.field.balance')"
                                    :rules="carnoValid"
                                    @click="receiptRecordsDialogBox"
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-autocomplete
                                    dense
                                    :class="relatedReceiptsItems.length > 0 ? 'accent lighten-3' : ''"
                                    cache-items
                                    :items="relatedReceiptsItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="redirectOnEditReceiptPage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('invoice.field.receiptNo')"
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-autocomplete
                                    dense
                                    :class="relatedCreditInvoicesItems.length > 0 ? 'accent lighten-3' : ''"
                                    cache-items
                                    :items="relatedCreditInvoicesItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="redirectOnEditCreditInvoicePage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('creditInvoicePdf.customerInvoiceNo')"
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-radio-group hide-no-data hide-details="auto" dense single-line v-model="selectedStatus" :disabled="isFieldReadable" readonly class="text-caption">
                                    <template v-slot:label>
                                      <div>{{$t('invoice.field.status')}}</div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-col>
                                <v-col cols="6">
                                  <v-select
                                    v-model="selectedGroup"
                                    :items="groupValue"
                                    item-text="description"
                                    item-value="id"
                                    return-object
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :label="$t('invoice.invoiceStatus')"
                                    @change="changeCategoryStatus"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-row dense>
                                <v-col cols="12">
                                  <v-checkbox
                                    dense
                                    :readonly="!invoiceId || originModel.status !== 1 || (!!invoiceId && originModel.status == 1 && originModel.customer.book_kiping_no && !!originModel.accounting_update)"
                                    hide-details="auto"
                                    @click="bookkeepingConfirmationDialog = !!invoiceId && originModel.status == 1 && !originModel.customer.book_kiping_no && !originModel.accounting_update ? true : false"
                                    v-model="model.accounting_update"
                                    @click.native.stop="bookkeepingRecordsDialog = originModel.accounting_update && originModel.status == 1 || originModel.status == 2 ? true : false"
                                    :label="$t('invoice.field.accountUpdate')"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    :readonly="isFieldReadable"
                                    hide-details="auto"
                                    rows="8"
                                    v-model="model.remarks"
                                    :label="$t('invoice.field.remarks')"
                                    :rules="[lessThanValidation($t('invoice.field.remarks'), $t('common.less'), 250, model.remarks)]"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical/>
                        <v-col cols="3" v-if="!invoiceId || (invoiceId && accessRight.includes('financial'))">
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{$t('invoice.total')}}
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{numberWithCommas(model.total)}}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">
                                  {{$t('invoice.field.discount')}}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :readonly="isFieldReadable"
                                    height="18"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="discountValid"
                                    @input="invoicePriceCalculation"
                                    v-model="model.discount_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary">mdi-percent-outline</v-icon>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{numberWithCommas(model.discount)}}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{$t('invoice.field.afterDiscount')}}
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{numberWithCommas(model.after_discount)}}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">
                                  {{$t('invoice.field.vat')}}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :readonly="isFieldReadable"
                                    :disabled="includeVatCharge ? false : true"
                                    height="18"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="vatPrecentageValid"
                                    @input="invoicePriceCalculation"
                                    v-model="model.vat_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary">mdi-percent-outline</v-icon>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{numberWithCommas(model.vat)}}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{$t('invoice.field.grandTotal')}}
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{numberWithCommas(model.grand_total)}}
                              <v-icon @click="editGrandTotal = true"> mdi-pencil-circle </v-icon>
                            </v-col>
                          </v-row>
                          <v-row dense v-if="editGrandTotal || model.grand_total_requestd">
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6" class="font-weight-bold">
                                  {{ $t("company.field.grandTotalRequested") }}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    height="18"
                                    :readonly="isFieldReadable"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="grandTotalRequestedValid"
                                    @change="grandTotalRequested"
                                    v-model="model.grand_total_requestd"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.grand_total_requestd) }}
                              <v-icon @click="cancelGrandTotalRequested"> mdi-cancel </v-icon>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="invoiceId"
        docType="Invoice"
        docMode="sales"
        docItems="InvoiceItem"
        docEmailSent="InvoiceEmailSent"
      />
      <BookkeepingRecordsDialog :bookkeepingRecordsDialog.sync="bookkeepingRecordsDialog" type="Invoice" :docId.sync="originModel.invoice_document_number" docMode="sales" />
      <ContactDialog :dialog.sync="dialog" :customer_id.sync="customer_id"/>
      <ProductDialog @onProductChange="onProductChange" v-if="customer_id" :dialog.sync="productDialog" :productDialogIndex.sync="productDialogIndex" :mainDocIndexForMultiple.sync="mainDocIndexForMultiple" />
      <CustomerDetail :model.sync="customerAdditionalDetail" :isFieldReadable="isFieldReadable" @saveCustomerAdditionalDetail="saveCustomerAdditionalDetail" :customerDetailDialog.sync="customerDetailDialog"/>
      <EmployeeDialog @onEmployeeChange="onEmployeeChange" :dialog.sync="employeeDialog" />
      <CustomerDialog @onCustomerChange="onCustomerChange" :dialog.sync="customerDialog" />
      <!-- select multiple order dialog -->
      <multiple-order :isMultiple.sync="isMultiple" :multipleOrderDialog.sync="multipleOrderDialog" :multipleOrderModel.sync="multipleOrderModel" />
      <!-- select multiple delivery dialog -->
      <multiple-delivery :isMultiple.sync="isMultiple" :multipleDeliveryDialog.sync="multipleDeliveryDialog" :multipleDeliveryModel.sync="multipleDeliveryModel" />
      <!-- select multiple proforma dialog -->
      <multiple-proforma :isMultiple.sync="isMultiple" :multipleProformaDialog.sync="multipleProformaDialog" :multipleProformaModel.sync="multipleProformaModel" />
      <ContactListDialog v-if="customer_id" @onContactChange="onContactChange" :dialog.sync="contactDialog" :customerId.sync="customer_id"/>
      <status-confirmation-dialog v-if="confirmationDialog" :dialog.sync="confirmationDialog" :setOrderStatus.sync="setOrderStatus" @changeStatus="saveInvoice"/>
      <CreditInvoiceConfirmationDialog v-if="creditInvoiceConfirmationDialog" :dialog.sync="creditInvoiceConfirmationDialog" :creditInvoiceType.sync="creditInvoiceType" @createCreditInvoice="createCreditInvoice" :model="model" />
      <BookkeepingConfirmationDialog :dialog.sync="bookkeepingConfirmationDialog" :bookkeepingConfirmation="bookkeepingConfirmation" />
      <RemarksDialog :dialog.sync="remarksDialog" :status="originModel.status" :docId="invoiceId" :docType="18" />
      <confirmation-dialog :dialog.sync="confirmationDialogStatus" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
      <ReceiptRecordsDialog :receiptRecordsDialog.sync="receiptRecordsDialog" />
      <AttachedDocumentsDialog :docId="invoiceId" docType="Invoice" :dialog.sync="attachedDocumentsDialog" />
      <PeriodClosureConfirmationDialog :dialog.sync="periodClosureConfirmationDialog" :periodClosureConfirmation="periodClosureConfirmation" />
      <StatusCheckInvoice v-if="statusCheckInvoice" :dialog.sync="statusCheckInvoice" />
      <CommonCopyAndCreateDialog v-if="copyAndCreateDialog" :dialog.sync="copyAndCreateDialog" :modelData="model" type="Invoice" @createCreditInvoice="createCreditInvoice" />
      <SuppliedDialog :dialog.sync="suppliedDialogBox" />
      <RemainingSupplied :dialog.sync="remainingSuppliedDialogBox" :supplied="model.invoiceItems" @redirect="creditInvoiceConfirmationDialogBox" doc="invoice" :docId="model.id" :model="model" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import {changedFormData} from '@/utils';
import FormSkeleton from '@/components/skeleton/FormSkeleton';
import Alert from "@/components/Alert";
import ContactDialog from '../customer/Steps/ContactDialog.vue';
import ProductDialog from "@/components/moduleTableDialog/Dialog/ProductDialog";
import ProductItemSelect from '../../../components/ProductItemSelect.vue'
import CustomerDialog from "@/components/moduleTableDialog/Dialog/CustomerDialog";
import ActivityLogTableDialog from '@/components/activityLog/Dialogs/ActivityLogTableDialog.vue';
import BookkeepingRecordsDialog from '@/components/bookkeepingRecords/Dialogs/BookkeepingRecordsDialog.vue';
import CustomerDetail from "@/components/moduleTableDialog/Dialog/CustomerDetail";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import ContactListDialog from "@/components/moduleTableDialog/Dialog/ContactListDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import StatusConfirmationDialog from '@/components/StatusConfirmationDialog';
import CreditInvoiceConfirmationDialog from '@/components/CreditInvoiceConfirmationDialog';
import MultipleOrder from './Dialog/MultipleOrder';
import MultipleDelivery from './Dialog/MultipleDelivery';
import MultipleProforma from './Dialog/MultipleProforma';
import {formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate, getAuxiliaryZtableValueByName} from '@/utils';
import BookkeepingConfirmationDialog from '@/components/BookkeepingConfirmationDialog';
import RemarksDialog from "@/components/RemarksDialog";
import ConfirmationDialog from '@/components/ConfirmationDialog';
import ReceiptRecordsDialog from '@/components/receiptRecords/Dialogs/ReceiptRecordsDialog.vue';
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import DocumentNumberConfirmation from "@/components/DocumentNumberConfirmation";
import PeriodClosureConfirmationDialog from '@/components/PeriodClosureConfirmationDialog';
import StatusCheckInvoice from '@/components/StatusCheckInvoice.vue';
import CommonCopyAndCreateDialog from '@/components/CommonCopyAndCreateDialog';
import SuppliedDialog from '@/components/SupliedDialog';
import RemainingSupplied from '@/components/RemainingSuppliedDialog';
import NextAndPreviousCommon from '@/components/NextAndPreviousCommon';
import SuppliedQuantityDialog from '@/components/SuppliedQuantityDialog';
import WarehouseInput from '@/components/WarehouseInput';
import LoginGoogle from '@/components/LoginGoogle';
import CommonCalendar from '@/components/CommonCalendar';
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: 'InvoiceAction',
  components: {
    FormSkeleton,
    Alert,
    ContactDialog,
    ProductDialog,
    CustomerDetail,
    ActivityLogTableDialog,
    BookkeepingRecordsDialog,
    EmployeeDialog,
    CustomerDialog,
    ContactListDialog,
    HtmlToPdf,
    ProductItemSelect,
    StatusConfirmationDialog,
    CreditInvoiceConfirmationDialog,
    MultipleOrder,
    MultipleDelivery,
    MultipleProforma,
    BookkeepingConfirmationDialog,
    RemarksDialog,
    ConfirmationDialog,
    ReceiptRecordsDialog,
    AttachedDocumentsDialog,
    DocumentNumberConfirmation,
    PeriodClosureConfirmationDialog,
    StatusCheckInvoice,
    CommonCopyAndCreateDialog,
    SuppliedDialog,
    RemainingSupplied,
    NextAndPreviousCommon,
    SuppliedQuantityDialog,
    WarehouseInput,
    LoginGoogle,
    CommonCalendar
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      message: '',
      modeltotalstore: 0.00,
      customerDetailDialog: false,
      alert: false,
      loading: false,
      valid: false,
      confirmationDialog: false,
      setOrderStatus: null,
      customerAdditionalDetail: {},
      model: {
        is_multiple: false,
        status: 0,
        total: 0.00,
        discount: 0.00,
        after_discount: 0.00,
        vat: 0.00,
        grand_total: 0.00,
        delivery_date: '',
        invoice_date: '',
        payment_date:''
      },
      originModel: {},
      selectedCustomer: null,
      customerDialog: false,
      selectedContact: null,
      contactDialog: false,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      bookkeepingRecordsDialog: false,
      receiptRecordsDialog: false,
      startDateMenu: false,
      deliveryDateMenu: false,
      paymentDateMenu: false,
      endDateMenu: false,
      includeVatCharge: false,
      customerOrderNoRequired: false,
      invoiceItems: [{
        selectedProduct: null,
        unit_price: null,
        item_description: null,
        total: null,
      }],
      customer_id: null,
      customerPage: 1,
      customerLimit: 10,
      contactSearch: '',
      contactPage: 1,
      contactLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      dialog: false,
      productDialog: false,
      productDialogIndex: null,
      selectedStatus: 0,
      selectedProduct: [],
      allSelectedProduct: false,
      copyFormLoading: true,
      fromDelivery: false,
      fromOrder: false,
      relatedDeliveryItems: [],
      relatedOrderItems: [],
      relatedProformaInvoiceItems: [],
      relatedQuotationItems: [],
      relatedReceiptsItems: [],
      relatedCreditInvoicesItems: [],
      multipleOrderDialog: false,
      multipleDeliveryDialog: false,
      multipleProformaDialog: false,
      isMultiple: false,
      fromMultipleDelivery: false,
      fromMultipleProformaInvoice: false,
      multipleOrderModel: null,
      multipleDeliveryModel: null,
      multipleProformaModel: null,
      mainDocIndexForMultiple: null,
      nextId: null,
      prevId: null,
      formattedDeliveryDate: '',
      formattedInvoiceDate:'',
      formattedPaymentDate:'',
      creditInvoiceConfirmationDialog: false,
      creditInvoiceType: null,
      bookkeepingConfirmationDialog: false,
      remarksDialog: false,
      confirmationDialogStatus: false,
      delete_item: null,
      groupValue: [],
      selectedGroup: null,
      attachedDocumentsDialog: false,
      saveOnlyCategoryStatus: false,
      itemDiscount: null,
      confirmDocumentNumberDialog: false,
      moreDocumentNumber: false,
      documentData: [],
      editGrandTotal: false,
      periodClosureConfirmationDialog: false,
      isProgressCircular: false,
      statusCheckInvoice: false,
      selectedWarehouse: null,
      copyAndCreateDialog: false,
      suppliedDialogBox: false,
      remainingSuppliedDialogBox: false
    }
  },
  computed: {
    statusList() {
      return [
        { name: this.$t('invoice.field.open'), id: 0 },
        { name: this.$t('invoice.field.finished'), id: 1 },
        { name: this.$t('invoice.field.canceled'), id: 2 }
      ]
    },
    isInvoiceId() {
      return this.invoiceId ? true : false;
    },
    invoiceYearValid() {
      return [
        v => !!v || this.$t('formRules.invoiceYearRequired'),
      ];
    },
    discountValid() {
      return [
        v => !v || /^\d{0,8}(\.\d{1,4})?$/.test(v) || this.$t('formRules.spaceValidValue'),
        v => !v || (v && String(v).length <= 12) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('quotation.field.discount'), type: this.$t('common.less'), size: 12 }),
        v => !v || v <= 100.00 || this.$t("formRules.discountValueExceedsLimit"),
      ];
    },
    invoiceId() {
      return this.$route.params.invoice_id
    },
    copiedInvoice() {
      return this.$route.params.copiedModel
    },
    ...mapGetters({
      invoiceById: 'invoice/invoiceById',
      deliveryById: 'delivery/deliveryById',
      orderById: 'order/orderById',
      quotationById: 'quotation/quotationById',
      locale: 'locale',
      profileById: 'profile/profileById',
      remarksList: 'remarks/remarksList',
      employeeById: 'employee/employeeById',
      customerById: 'customer/customerById',
      customerList: 'customer/customerList',
      contactList: 'contact/contactList',
      employeeList: 'employee/employeeList',
      invoiceQuery: 'invoice/invoiceQuery',
      accessRight: 'accessRight',
      user: 'user',
      moreThanOneUserLogout: 'moreThanOneUserLogout',
      proformaById: 'proforma/proformaById',
      bookkeepingConfirmation: 'bookkeepingConfirmation',
      periodClosureConfirmation: 'multiple/periodClosureConfirmation',
      receiptTaxInvoiceById: 'receiptTaxInvoice/receiptTaxInvoiceById',
      creditInvoiceById: 'creditInvoice/creditInvoiceById',
      returnCertificateById: 'returnCertificate/returnCertificateById',
    }),
    isFieldReadable () {
      return this.model.status == 0 ? false : true;
    },
    minDate() {
      if (this.invoiceId) {
        const date = parseDateYYYYMMDD(this.model.invoice_date)?.split('-')[0]
        return `${date}-01-01`
      } else {
        return null;
      }
    },
    maxDate() {
      if (this.invoiceId) {
        const date = parseDateYYYYMMDD(this.model.invoice_date)?.split('-')[0]
        return `${date}-12-31`
      } else {
        return null;
      }
    },
  },
  watch: {
    'model.invoice_date'(val) {
      const year = this.model.invoice_year
      this.formattedInvoiceDate =  this.profileById.date_format ? formatDateDDMMYYYY(val) : val;
      this.model.invoice_year = parseDateYYYYMMDD(val).split('-')[0]
      if (year && (this.model.invoice_year !== year)) {
        this.documentSeriesFunction();
      }
    },
    periodClosureConfirmation: {
      handler: function(val) {
        if (val) {
          this.periodClosureConfirmationDialog = true;
        }
      },
      deep: true
    },
    'model.delivery_date'(val) {
      this.formattedDeliveryDate =  this.profileById.date_format ? formatDateDDMMYYYY(val) : val; 
    },
    'model.payment_date'(val) {
      this.formattedPaymentDate =  this.profileById.date_format ? formatDateDDMMYYYY(val) : val; 
    },
    customerLimit(newVal,oldVal) {
      if(newVal !== oldVal)
        this.$store.dispatch('customer/GetCustomer', {page: 1, limit: this.customerLimit, order_by: 'name_1|asc'});
    },
    contactSearch(newVal,oldVal) {
      if(newVal !== oldVal)
        if (this.model.customer_id) {
          this.$store.dispatch('contact/GetContact', {page: 1, limit: 10, where_like: newVal ? 'id,name|'+newVal : newVal, where_and: 'record_type_number,record_type|'+this.model.customer_id+',customers', order_by: 'name|asc'});
        }
    },
    contactLimit(newVal,oldVal) {
      if(newVal !== oldVal)
        if (this.model.customer_id) {
          this.$store.dispatch('contact/GetContact', {page: 1, limit: this.contactLimit, where_and: 'record_type_number,record_type|'+this.model.customer_id+',customers', order_by: 'name|asc'});
        }
    },
    employeeLimit(newVal,oldVal) {
      if(newVal !== oldVal)
        this.$store.dispatch('employee/GetEmployee', {page: 1, limit: this.employeeLimit, order_by: 'name|asc'});
    },
    profileById() {
      if (Object.keys(this.profileById).length > 0 && !this.$route.params.invoice_id && !this.model.invoice_document_number && !this.$route.query.orderId && !this.$route.query.proformaId && !this.$route.query.deliveryId) {
        this.model.vat_percentage = this.profileById.vat_percentage;
      }
      this.formattedInvoiceDate = this.profileById.date_format ? formatDateDDMMYYYY(this.model.invoice_date) : this.model.invoice_date;
      this.formattedDeliveryDate = this.profileById.date_format ? formatDateDDMMYYYY(this.model.delivery_date) : this.model.delivery_date;
      this.formattedPaymentDate = this.profileById.date_format ? formatDateDDMMYYYY(this.model.payment_date) : this.model.payment_date;
    },
    invoiceById() {
      if (this.$route.params.invoice_id) {
        if (Object.keys(this.invoiceById).length > 0) {
          this.model = Object.assign({}, this.invoiceById)
          if(this.model.invoice_date) {
            this.model.invoice_date = new Date(this.model.invoice_date).toISOString().substring(0,10);
          }
          if(this.model.delivery_date) {
            this.model.delivery_date = new Date(this.model.delivery_date).toISOString().substring(0,10);
          }
          this.relatedOrderItems = this.model.relatedOrder.filter(item => item.related_model_id);
          if (this.$route.query.type !== 'copy-create') {
            this.relatedDeliveryItems = this.model.relatedDeliveryNote.filter(item => item.related_model_id);
            this.relatedProformaInvoiceItems = this.model.relatedProformaInvoice.filter(item => item.related_model_id);
          }
          this.relatedQuotationItems = this.model.relatedQuotation.filter(item => item.related_model_id);
          this.relatedReceiptsItems = this.model.relatedReceipts.filter(item => item.related_model_id);
          this.relatedCreditInvoicesItems = this.model.relatedCreditInvoices.filter(item => item.related_model_id);
          this.selectedStatus = this.model.status;
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      } else if (this.$route.params.copiedModel) {
        if (Object.keys(this.invoiceById).length > 0) {
          this.model = Object.assign({}, this.invoiceById)
          if(this.model.delivery_date) {
            this.model.delivery_date = new Date(this.model.delivery_date).toISOString().substring(0,10);
          }
          delete this.model.created_at
          delete this.model.id;          
          delete this.model.delivery_note_id;
          delete this.model.delivery_note_document_number;
          delete this.model.deliveryNote;
          delete this.model.quotation_id;
          delete this.model.quotation;
          delete this.model.order_id;
          delete this.model.order;
          delete this.model.updated_at;
          delete this.model.relatedDeliveryNote;
          delete this.model.relatedOrder;
          delete this.model.relatedQuotation;
          delete this.model.relatedProformaInvoice;
          delete this.model.proforma_invoice_id;
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          this.model?.invoiceItems?.forEach(element => {
            delete element.supplied;
            delete element.doc_items_id;
            delete element.multiple_doc_type;
            delete element.inventory;
            delete element.remainingQuantity;
            delete element.calculated_discount;
            delete element.multiple_doc_type;
          });
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    deliveryById() {
      if (!this.$route.params.invoice_id && !this.$route.params.copiedModel) {
        if (Object.keys(this.deliveryById).length > 0) {
          this.model = Object.assign({}, this.model, {...this.deliveryById, title: this.deliveryById.delivery_company, invoiceItems: this.deliveryById.deliveryNoteItems});
          delete this.model.delivery_company;
          delete this.model.deliveryNoteItems;
          delete this.model.created_at;
          delete this.model.delivery_no;
          delete this.model.delivery_time;
          delete this.model.id;
          delete this.model.delivery_note_document_number;
          delete this.model.order;
          delete this.model.order_id;
          delete this.model.quotation;
          delete this.model.relatedInvoices;
          delete this.model.relatedProformaInvoicesOrder;
          delete this.model.relatedOrder;
          delete this.model.relatedQuotations;
          delete this.model.quotation_id;
          delete this.model.updated_at;
          delete this.model.year;
          this.model?.invoiceItems?.forEach(element => {
            delete element.inventory;
            delete element.supplied;
            delete element.created_at;
            delete element.updated_at;
            delete element.calculated_discount;
            delete element.document_number;
            delete element.document_date;
            delete element.title;
            delete element.customer_order_no;
            if (this.$route.query.type === 'copy-create') {
              this.model?.invoiceItems?.forEach(element => {
                delete element.multiple_doc_type;
              });
            }
          });
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    orderById() {
      if (!this.$route.params.invoice_id && !this.$route.params.copiedModel) {
        if (Object.keys(this.orderById).length > 0) {
          this.model = Object.assign({}, this.model, {...this.orderById, invoiceItems: this.orderById.orderItems});
          delete this.model.orderItems;
          delete this.model.created_at;
          delete this.model.date_time_printed;
          delete this.model.delivery_note_id;
          delete this.model.relatedDocument;
          delete this.model.delivery_note_document_number;
          delete this.model.invoice_id;
          delete this.model.order_date;
          delete this.model.id;
          delete this.model.quotation;
          delete this.model.relatedInvoices;
          delete this.model.quotation_id;
          delete this.model.updated_at;
          this.model?.invoiceItems?.forEach(element => {
            delete element.inventory;
            delete element.supplied;
            delete element.created_at;
            delete element.updated_at;
            delete element.calculated_discount;
            delete element.document_number;
            delete element.document_date;
            delete element.title;
            delete element.customer_order_no;
          });
          if (this.$route.query.type === 'copy-create') {
            this.model?.invoiceItems?.forEach(element => {
              delete element.multiple_doc_type;
            });
          }
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    quotationById() {
      if (!this.$route.params.invoice_id && !this.$route.params.copiedModel) {
        if (Object.keys(this.quotationById).length > 0) {
          this.model = Object.assign({}, this.model, {...this.quotationById, invoiceItems: this.quotationById.quotationItems});
          delete this.model.quotationItems;
          delete this.model.created_at;
          delete this.model.time_printed;
          delete this.model.order_no;
          delete this.model.relatedDocument;
          delete this.model.delivery_note_document_number;
          delete this.model.invoice_id;
          delete this.model.order_date;
          delete this.model.quotation_date;
          delete this.model.quotation_validity;
          delete this.model.name;
          delete this.model.invoice_no;
          delete this.model.quotationStatus;
          delete this.model.id;
          delete this.model.quotation;
          delete this.model.relatedInvoices;
          delete this.model.invoice_no;
          delete this.model.updated_at;
          this.model?.invoiceItems?.forEach(element => {
            delete element.id;
            delete element.quotation_id;
            delete element.inventory;
            delete element.supplied;
            delete element.created_at;
            delete element.updated_at;
            delete element.remainingQuantity;
          });
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    proformaById() {
      if (!this.$route.params.invoice_id && !this.$route.params.copiedModel) {
        if (Object.keys(this.proformaById).length > 0) {
          this.model = Object.assign({}, this.model, {...this.proformaById, invoiceItems: this.proformaById.proformaInvoiceItems});
          delete this.model.proformaInvoiceItems;
          delete this.model.created_at;
          delete this.model.date_time_printed;
          delete this.model.order_id;
          delete this.model.relatedDocument;
          delete this.model.invoice_id;
          delete this.model.proforma_invoice_date;
          delete this.model.id;
          delete this.model.order;
          delete this.model.delivery_note_document_number;
          delete this.model.relatedOrder;
          delete this.model.quotation_id;
          delete this.model.updated_at;
          delete this.model.proforma_invoice_year;
          delete this.model.deliveryNote_model_number
          delete this.model.delivery_note_id;
          delete this.model.order_model_number;
          delete this.model.main_number;
          delete this.model.quotation_model_number;
          delete this.model.relatedDeliveryNote;
          delete this.model.relatedInvoices;
          this.model?.invoiceItems?.forEach(element => {
            delete element.proforma_invoice_year;
            delete element.id;
            delete element.inventory;
            delete element.supplied;
            delete element.created_at;
            delete element.updated_at;
            delete element.calculated_discount;
            delete element.document_number;
            delete element.document_date;
            delete element.title;
            delete element.customer_order_no;
            if (this.$route.query.type === 'copy-create') {
              this.model?.invoiceItems?.forEach(element => {
                delete element.multiple_doc_type;
              });
            }
          });
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    receiptTaxInvoiceById() {
      if (Object.keys(this.receiptTaxInvoiceById).length > 0) {
        this.model = Object.assign({}, this.model, {...this.receiptTaxInvoiceById, invoiceItems: this.receiptTaxInvoiceById.receiptTaxInvoiceItems})
        delete this.model.id;          
        delete this.model.receiptTaxInvoiceItems;
        delete this.model.receipt_tax_invoice_year;
        delete this.model.receipt_tax_invoice_date;
        delete this.model.balance;
        delete this.model.receipt_tax_invoice_payment_date;
        delete this.model.accounting_update;
        delete this.model.delivery_company;
        delete this.model.receipt_tax_invoice_delivery_date;
        delete this.model.car_no;
        delete this.model.with_holding_tax;
        delete this.model.recipt_tax_invoice_document_number;
        delete this.model.document_number_series;
        delete this.model.warehouse;
        delete this.model.receiptTaxInvoiceStatus;
        delete this.model.receiptTaxInvoicePayments;
        this.paidTotal = this.receiptTaxInvoiceById.grand_total;
        this.model.title = this.receiptTaxInvoiceById.title;
        this.model?.invoiceItems?.forEach(element => {
          delete element.id;
          delete element.receipt_tax_invoice_id;
          delete element.receipt_tax_invoice_year;
          delete element.receipt_tax_invoice_item_id;
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element.calculated_discount;
          delete element.multiple_doc_type;
        });
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    creditInvoiceById() {
      if (Object.keys(this.creditInvoiceById).length > 0) {
        this.model = Object.assign({}, this.model, {...this.creditInvoiceById, invoiceItems: this.creditInvoiceById.creditInvoiceItems})
        delete this.model.id;          
        delete this.model.creditInvoiceItems;
        delete this.model.credit_invoice_year;
        delete this.model.credit_invoice_date;
        delete this.model.balance;
        delete this.model.creditInvoiceStatus;
        delete this.model.accounting_update;
        delete this.model.creditInvoicePayments;
        delete this.model.relatedReturnCertificate;
        delete this.model.credit_invoice_document_number;
        delete this.model.document_number_series;
        delete this.model.warehouse;
        this.paidTotal = this.creditInvoiceById.grand_total;
        this.model.title = this.creditInvoiceById.title;
        this.model?.invoiceItems?.forEach(element => {
          delete element.id;
          delete element.credit_invoice_id;
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element['multiple_doc_type'];
          delete element['calculated_discount'];
        });
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    returnCertificateById() {
      if (Object.keys(this.returnCertificateById).length > 0) {
        this.model = Object.assign({}, this.model, {...this.returnCertificateById, invoiceItems: this.returnCertificateById.ReturnCertificateItems})
        delete this.model.id;          
        delete this.model.warehouse;
        delete this.model.year;
        delete this.model.return_certificate_date;
        delete this.model.return_certificate_time;
        delete this.model.return_certificate_company;
        delete this.model.return_certificate_no;
        delete this.model.is_invoiced;
        delete this.model.return_certificate_document_number;
        delete this.model.document_number_series;
        delete this.model.balance;
        delete this.model.ReturnCertificateItems;
        delete this.model.returnCertificateStatus;
        this.paidTotal = this.returnCertificateById.grand_total;
        this.model.title = this.returnCertificateById.return_certificate_company;
        this.selectedCustomer = {
          id: this.model?.customer_id,
          name_1: this.model.customer?.name_1,
        };
        this.model?.invoiceItems?.forEach(element => {
          delete element.id;
          delete element.return_certificate_id;
          delete element.delivery_item_id;
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.remainingQuantity;
          delete element.updated_at;
          delete element.calculated_discount;
          delete element.multiple_doc_type;
        });
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async multipleOrderModel() {
      if (this.isMultiple) {
        if (Object.keys(this.multipleOrderModel).length > 0) {
          this.model = Object.assign({}, this.model, {...this.multipleOrderModel, invoiceItems: this.multipleOrderModel.data});
          this.selectedCustomer = this.model.selectedCustomer;
          this.model.customer_name = this.model.selectedCustomer.name_1;
          this.includeVatCharge = this.model.vat_charge;
          this.customerOrderNoRequired = this.model.customer_order_no_compulsory;
          delete this.model.data;
          delete this.model.vat_charge;
          delete this.model.selectedCustomer;
          delete this.model.customer_order_no_compulsory;
          this.customer_id = this.model?.customer_id;
          if(this.customer_id) {
            await this.$store.dispatch('customer/GetCustomerById', this.customer_id)
            this.onInvoiceDateChange();
          }
          this.originModel = Object.assign({}, this.originModel, this.model);
          this.invoiceItems = [];
          this.selectedProduct = [];
          this.model.is_multiple = true;
          this.fromMultipleDelivery = false;
          this.fromMultipleProformaInvoice = false;
          this.model.invoiceItems.forEach((element) => {
            let orderItems = element['orderItems'];
            let orderId = element['id'];
            let orderTitle = element['title'];
            delete element['address'];
            delete element['after_discount'];
            delete element['authorized_dealer_no'];
            delete element['cellular'];
            delete element['contact_id'];
            delete element['country'];
            delete element['created_at'];
            delete element['customer'];
            delete element['customer_id'];
            delete element['customer_name'];
            delete element['customer_order_no'];
            delete element['date_time_printed'];
            delete element['delivery_date'];
            delete element['delivery_note_id'];
            delete element ['delivery_note_document_number'];
            delete element['discount'];
            delete element['discount_percentage'];
            delete element['email'];
            delete element['employee_id'];
            delete element['fax'];
            delete element['grand_total'];
            delete element['id'];
            delete element['invoice_id'];
            delete element['order_date'];
            delete element['phone_1'];
            delete element['phone_2'];
            delete element['quotation_id'];
            delete element['remarks'];
            delete element['status'];
            delete element['title'];
            delete element['total'];
            delete element['town'];
            delete element['updated_at'];
            delete element['vat'];
            delete element['vat_percentage'];
            delete element['orderItems'];
            let invoiceItemsData = [];
            if (orderItems.length > 0) {
              orderItems.forEach((itemElement) => {
                let orderItemId;
                orderItemId = itemElement.id;
                delete itemElement['created_at'];
                delete itemElement['id'];
                delete itemElement['line_number'];
                delete itemElement['quotation_item_id'];
                delete itemElement['remainder'];
                delete itemElement['updated_at'];
                let unit_price_change = this.numberWithCommas(itemElement['unit_price'], 'noFloat');
                let total_price_change = this.numberWithCommas(itemElement['total'], 'noFloat');
                invoiceItemsData.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, order_item_id: orderItemId, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
                this.selectedProduct.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, order_item_id: orderItemId, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
              });
            }
            this.invoiceItems.push({order_id: orderId, title: orderTitle, invoiceItems: invoiceItemsData});
          });
          this.$refs.form.validate();
        }
      }
    },
    async multipleDeliveryModel() {
      if (this.isMultiple) {
        if (Object.keys(this.multipleDeliveryModel).length > 0) {
          this.model = Object.assign({}, this.model, {...this.multipleDeliveryModel, invoiceItems: this.multipleDeliveryModel.data});
          this.selectedCustomer = this.model.selectedCustomer;
          this.model.customer_name = this.model.selectedCustomer.name_1;
          this.includeVatCharge = this.model.vat_charge;
          this.customerOrderNoRequired = this.model.customer_order_no_compulsory;
          delete this.model.data;
          delete this.model.vat_charge;
          delete this.model.selectedCustomer;
          delete this.model.customer_order_no_compulsory;
          this.customer_id = this.model?.customer_id;
          if(this.customer_id) {
            await this.$store.dispatch('customer/GetCustomerById', this.customer_id)
            this.onInvoiceDateChange();
          }
          this.originModel = Object.assign({}, this.originModel, this.model);
          this.invoiceItems = [];
          this.selectedProduct = [];
          this.model.is_multiple = true;
          this.fromMultipleDelivery = true;
          this.fromMultipleProformaInvoice = false;
          this.model.invoiceItems.forEach((element) => {
            let orderItems = element['deliveryNoteItems'];
            let orderId = element['id'];
            let orderTitle = element['delivery_company'];
            delete element['address'];
            delete element['after_discount'];
            delete element['authorized_dealer_no'];
            delete element['cellular'];
            delete element['contact_id'];
            delete element['country'];
            delete element['customer'];
            delete element['customer_id'];
            delete element['customer_name'];
            delete element['customer_order_no'];
            delete element['created_at'];
            delete element['id'];
            delete element['updated_at'];
            delete element['proforma_invoice_date'];
            delete element['order_id'];
            delete element['delivery_note_id'];
            delete element ['proforma_invoice_item_id'];
            delete element['discount'];
            delete element['discount_percentage'];
            delete element['email'];
            delete element['employee_id'];
            delete element['fax'];
            delete element['grand_total'];
            delete element['payment_date'];
            delete element['phone_1'];
            delete element['phone_2'];
            delete element['remarks'];
            delete element['status'];
            delete element['title'];
            delete element['total'];
            delete element['town'];
            delete element['vat'];
            delete element['delivery_note_item_id']
            delete element['vat_percentage'];
            delete element['deliveryNoteItems'];
            let invoiceItemsData = [];
            if (orderItems.length > 0) {
              orderItems.forEach((itemElement) => {
                let deliveryItemId;
                deliveryItemId = itemElement.id;
                delete itemElement['created_at'];
                delete itemElement['id'];
                delete itemElement['line_number'];
                delete itemElement['quotation_item_id'];
                delete itemElement['order_item_id'];
                delete itemElement['remainder'];
                delete itemElement['proforma_invoice_item_id'];
                delete itemElement['updated_at'];
                let unit_price_change = this.numberWithCommas(itemElement['unit_price'], 'noFloat');
                let total_price_change = this.numberWithCommas(itemElement['total'], 'noFloat');
                invoiceItemsData.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, delivery_note_item_id: deliveryItemId, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
                this.selectedProduct.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, delivery_note_item_id: deliveryItemId, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
              })
            }
            this.invoiceItems.push({delivery_note_id: orderId, title: orderTitle, invoiceItems: invoiceItemsData});
          });
          this.$refs.form.validate();
        }
      }
    },
    async multipleProformaModel() {
      if (this.isMultiple) {
        if (Object.keys(this.multipleProformaModel).length > 0) {
          this.model = Object.assign({}, this.model, {...this.multipleProformaModel, invoiceItems: this.multipleProformaModel.data});
          this.selectedCustomer = this.model.selectedCustomer;
          this.model.customer_name = this.model.selectedCustomer.name_1;
          this.includeVatCharge = this.model.vat_charge;
          this.customerOrderNoRequired = this.model.customer_order_no_compulsory;
          delete this.model.data;
          delete this.model.vat_charge;
          delete this.model.selectedCustomer;
          delete this.model.customer_order_no_compulsory;
          this.customer_id = this.model?.customer_id;
          if(this.customer_id) {
            await this.$store.dispatch('customer/GetCustomerById', this.customer_id)
            this.onInvoiceDateChange();
          }
          this.originModel = Object.assign({}, this.originModel, this.model);
          this.invoiceItems = [];
          this.selectedProduct = [];
          this.model.is_multiple = true;
          this.fromMultipleDelivery = false;
          this.fromMultipleProformaInvoice = true;
          this.model.invoiceItems.forEach((element) => {
            let orderItems = element['proformaInvoiceItems'];
            let orderId = element['id'];
            let orderTitle = element['title'];  
            delete element['address'];
            delete element['after_discount'];
            delete element['authorized_dealer_no'];
            delete element['cellular'];
            delete element['contact_id'];
            delete element['country'];
            delete element['customer'];
            delete element['customer_id'];
            delete element['customer_name'];
            delete element['customer_order_no'];
            delete element['created_at'];
            delete element['id'];
            delete element['updated_at'];
            delete element['proforma_invoice_date'];
            delete element['order_id'];
            delete element['delivery_note_id'];
            delete element ['proforma_invoice_document_number'];
            delete element['discount'];
            delete element['discount_percentage'];
            delete element['email'];
            delete element['employee_id'];
            delete element['fax'];
            delete element['grand_total'];
            delete element['payment_date'];
            delete element['phone_1'];
            delete element['phone_2'];
            delete element['remarks'];
            delete element['status'];
            delete element['title'];
            delete element['total'];
            delete element['town'];
            delete element['vat'];
            delete element['vat_percentage'];
            delete element['proformaInvoiceItems'];
            let invoiceItemsData = [];
            if (orderItems.length > 0) {
              orderItems.forEach((itemElement) => {
                let proformaItemId;
                proformaItemId = itemElement.id;
                delete itemElement['created_at'];
                delete itemElement['id'];
                delete itemElement['line_number'];
                delete itemElement['quotation_item_id'];
                delete itemElement['remainder'];
                delete itemElement['updated_at'];
                delete itemElement['order_item_id'];
                let unit_price_change = this.numberWithCommas(itemElement['unit_price'], 'noFloat');
                let total_price_change = this.numberWithCommas(itemElement['total'], 'noFloat');
                invoiceItemsData.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, proforma_invoice_item_id: proformaItemId, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
                this.selectedProduct.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, proforma_invoice_item_id: proformaItemId, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
              })
            }
            this.invoiceItems.push({proforma_invoice_id: orderId, title: orderTitle, invoiceItems: invoiceItemsData});
          });
          this.$refs.form.validate();
        }
      }
      this.invoicePriceCalculation();
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Invoice',id:this.model.id,is_locked:false});
        this.$store.dispatch('LogOut');
        this.$router.push({ name: 'login' });
      }
    },
    bookkeepingConfirmation: {
      handler: function(val) {
        if (val) {
          this.bookkeepingConfirmationDialog = true;
        }
      },
      deep: true
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.handler)
    window.addEventListener('popstate', this.onpopstateFunction)

    if (this.$route.params.invoice_id) {
      await this.$store.dispatch('invoice/GetInvoiceById', this.$route.params.invoice_id);
      if (!this.model.is_locked && this.$route.params.invoice_id) {
       await this.$store.dispatch('PostDocumentOpenAction',{table:'Invoice',id:this.$route.params.invoice_id,is_locked:true});
      }
    }
  },
  async mounted() {
    this.onMounteUpdated();
    if (this.invoiceId) {
      this.$store.dispatch('remarks/GetRemarks', {where: 'document_type|'+18, where_and: 'document_id|'+this.invoiceId})
    }
    this.selectedEmployee = this.user.name
  },
  beforeDestroy() {
    if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
      this.$store.dispatch('PostDocumentOpenAction',{table:'Invoice',id:this.model.id,is_locked:false});
    }
    this.$refs.form ? this.$refs.form.reset() : '';
    this.model = {};
    this.customer_id = null;
    this.dialog = false;
    this.fromDelivery = false;
    this.fromOrder = false;
    this.creditInvoiceConfirmationDialog = false;
    this.creditInvoiceType = null;
    this.$store.commit('customer/SET_CUSTOMER_BY_ID', {});
    this.$store.commit('contact/SET_CONTACT', []);
    this.$store.commit('invoice/SET_INVOICE_BY_ID', {});
    this.$store.commit('customer/SET_CUSTOMER', []);
    this.$store.commit('SET_USER_LOGOUT', false)
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    formatDateDDMMYYYY,
    parseDateYYYYMMDD,
    todayDate,
    receiptRecordsDialogBox() {
      if (this.$route.params.invoice_id) {
        this.receiptRecordsDialog = true;
      }
    },
    creditInvoiceConfirmationDialogBox() {
      if (this.model && this.model.invoiceItems && this.model.invoiceItems.every(ele => ele.remainingQuantity === 0)) {
        this.suppliedDialogBox = true;
      } else {
        if (this.model.status == 0) {
          this.statusCheckInvoice = true;
        } else {
          this.creditInvoiceConfirmationDialog = true;
        }
      }
    },
    suppliedQty() {
      this.remainingSuppliedDialogBox = true;
    },
    async documentSeriesFunction() {
      this.documentData = []
      await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Invoice,${this.model.invoice_year}`).then((res) => {
        if (res.length > 1) {
          this.moreDocumentNumber = true;
          this.documentData = res;
        } else {
          this.moreDocumentNumber = false;
        }}
      )
      await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Invoice,${this.model.invoice_year}`).then((res) => {
        if (res.length > 0) {
          this.confirmDocumentNumberDialog = false;
        } else {
          if (this.invoiceId) {
            this.confirmDocumentNumberDialog = false;
          } else {
            this.confirmDocumentNumberDialog = true;
          }
        }
      })
    },
    async cancelGrandTotalRequested() {
      this.editGrandTotal = false;
      this.model.grand_total_requestd = '';
      // await this.$store.dispatch('invoice/GetInvoiceById', this.$route.params.invoice_id);
      this.grandTotalRequested();
    },
    onpopstateFunction() {
      const desiredRoute = '/invoice'; 
      if (this.$route.path === '/addInvoice' && this.$route.query && Object.keys(this.$route.query).length > 0) {
        this.$router.push(desiredRoute) 
      }
    },
    async handler(event) {
      if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Invoice',id:this.model.id,is_locked:false});
      }
      event.preventDefault();
    },
    async onMounteUpdated() {
      if (this.$route.params.invoice_id || this.$route.params.copiedModel || this.$route.query.deliveryId || this.$route.query.orderId || this.$route.query.proformaId || this.$route.query.quotationId || this.$route.query.receiptTaxInvoiceId || this.$route.query.creditInvoiceId || this.$route.query.returnCertificateId) {
        // invoice edit type
        //  set order data if item remaining
        // Invoice for edit mode
        if (this.$route.params.invoice_id) {
          this.documentNextAndPrevious();
          await this.$store.dispatch('invoice/GetInvoiceById', this.$route.params.invoice_id).then((res) => {
            if (res.invoice_document_number) {
              document.title = document.title + '   ' + res.invoice_document_number
            }
          });
          this.selectedGroup = this.model.status_one;
          this.selectedWarehouse = this.model.warehouse;
          if (this.model.order_id) {
            this.fromOrder = true;
            //  get order data if item remaining
            this.$store.dispatch('order/GetOrderById', this.model.order_id);
          } else if (this.model.proforma_invoice_id) {
            this.$store.dispatch('proforma/GetProformaById', this.model.proforma_invoice_id);
          } else if (this.model.delivery_note_id) {
            this.fromDelivery = true;
          } else if (this.model.is_multiple) {
            this.isMultiple = true;
            if (this.model.multiple_delivery) {
              this.fromMultipleDelivery = true;
              this.fromMultipleProformaInvoice = false;
            } else if (this.model.multiple_proforma) {
              this.fromMultipleProformaInvoice = true;
              this.fromMultipleDelivery = false;
            } else {
              this.fromMultipleDelivery = false;
              this.fromMultipleProformaInvoice = false;
            }
          }
        } else if (this.$route.query.deliveryId) {
          //  create invoice from single delivery not
          this.fromDelivery = true;
          if (this.$route.query.type !== 'copy-create') {
            this.model.delivery_note_id = this.$route.query.deliveryId;
          }
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          await this.$store.dispatch('delivery/GetDeliveryById', this.$route.query.deliveryId);
          this.model.status = 0;
        } else if (this.$route.query.orderId) {
          // create invoice from single order
          this.fromOrder = true;
          if (this.$route.query.type !== 'copy-create') {
            this.model.order_id = this.$route.query.orderId;
          }
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          await this.$store.dispatch('order/GetOrderById', this.$route.query.orderId);
          this.model.status = 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.quotationId) {
          await this.$store.dispatch('quotation/GetQuotationById', this.$route.query.quotationId);
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          this.model.status = 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.proformaId) {
          if (this.$route.query.type !== 'copy-create') {
            this.model.proforma_invoice_id = this.$route.query.proformaId;
          }
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          await this.$store.dispatch('proforma/GetProformaById', this.$route.query.proformaId);
          this.model.status = 0;
        } else if (this.$route.query.receiptTaxInvoiceId) {
          await this.$store.dispatch('receiptTaxInvoice/GetReceiptTaxInvoiceById', this.$route.query.receiptTaxInvoiceId);
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          this.model.status = 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.creditInvoiceId) {
          await this.$store.dispatch('creditInvoice/GetCreditInvoiceById', this.$route.query.creditInvoiceId);
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          this.model.status = 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.returnCertificateId) {
          await this.$store.dispatch('returnCertificate/GetReturnCertificateById', this.$route.query.returnCertificateId);
          this.model.invoice_year = new Date().getFullYear();
          this.model.invoice_date = new Date().toISOString().substring(0,10);
          this.model.status = 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.params.copiedModel) {
          // Copy invoice
          this.copyFormLoading = false;
          await this.$store.dispatch('invoice/GetInvoiceById', this.$route.params.copiedModel);
          if (this.model.order_id) {
            this.fromOrder = true;
            //  get order data if item remaining
            this.$store.dispatch('order/GetOrderById', this.model.order_id);
          } else if (this.model.proforma_invoice_id) {
            this.$store.dispatch('proforma/GetProformaById', this.model.proforma_invoice_id);
          } else if (this.model.delivery_note_id) {
            this.fromDelivery = true;
          } else if (this.model.is_multiple) {
            this.isMultiple = true
            if (this.model.multiple_delivery) {
              this.fromMultipleDelivery = true;
              this.fromMultipleProformaInvoice = false;
            } else if (this.model.multiple_proforma) {
              this.fromMultipleProformaInvoice = true;
              this.fromMultipleDelivery = false;
            } else {
              this.fromMultipleDelivery = false;
              this.fromMultipleProformaInvoice = false;
            }
          }
          this.model.status = 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        }
        if(this.model.customer_id) {
          await this.$store.dispatch('customer/GetCustomerById', this.model.customer_id)
        }
        if (this.model.employee_id) {
          this.selectedEmployee = {id: this.model?.employee?.id, name: this.model?.employee?.name};
          this.model.employee_id = this.model?.employee_id;
        }
        this.customer_id = this.model?.customer_id;
        this.model.status_one = null;
        this.selectedCustomer = {id: this.model?.customer_id, name_1: this.model.customer?.name_1, fixed_discount: this.model.customer?.fixed_discount};
        if (this.model.contact) {
          this.selectedContact = {id: this.model.contact?.id, name: this.model.contact?.name};
        }
        this.includeVatCharge = this.model?.customer?.vat_charge;
        this.customerOrderNoRequired = this.model?.customer?.customer_order_no_compulsory;
        this.selectedStatus = this.model.status;
        this.model.balance = this.numberWithCommas(this.model.balance);
        this.selectedProduct = [];
        if (this.model.invoiceItems?.length < 1) {
          this.invoiceItems = [{
            selectedProduct: null,
            unit_price: null,
            item_description: null,
            total: null,
          }];
        } else {
          this.invoiceItems = [];
        }
        this.model.invoiceItems?.forEach((element, index) => {
          delete element['created_at'];
          delete element['updated_at'];
          delete element['quotation_item_id'];
          // delete element['order_id'];
          delete element['remainder'];
          if (this.fromOrder && !this.$route.params.invoice_id || this.model.proforma_invoice_id && !this.$route.params.invoice_id) {
            let orderItemId;
            if (this.fromOrder && this.$route.params.copiedModel) {
              orderItemId = element.order_item_id;
            } else if (this.model.proforma_invoice_id && this.$route.params.copiedModel) {
              orderItemId = element.proforma_invoice_item_id;
            } else {
              orderItemId = element.id;
            }
            delete element['quotation_id'];
            delete element['id'];
            delete element['order_item_id'];
            delete element['proforma_invoice_item_id']
            delete element['proforma_invoice_year'];
            delete element['delivery_note_document_number'];
            delete element['delivery_note_item_id'];
            delete element['invoice_id'];
            delete element['invoice_item_id'];
            delete element['invoice_year'];
            let unit_price_change = this.numberWithCommas(element['unit_price'], 'noFloat');
            let total_price_change = this.numberWithCommas(element['total'], 'noFloat');
            if (element['unit_price'] && !element['quantity']) {
              element = Object.assign({}, {...element, quantity: 1})
            }
            if (this.model.proforma_invoice_id) {
              let total_change_2 = element.remainingQuantity * element.unit_price;
              let total_change_3 = total_change_2 * element.line_discount / 100
              let total_change_4 = total_change_2 - total_change_3
              let total_change_price = this.numberWithCommas(total_change_4);
              let quantity_change = element["remainingQuantity"] ? element["remainingQuantity"] : element["quantity"]

              if (element.remainingQuantity) {
                this.invoiceItems.push(Object.assign({}, {...element, quantity: quantity_change, total: total_change_price, unit_price: unit_price_change, proforma_invoice_item_id: orderItemId, selectedProduct: {item_id: element.item_id, item_code: element.item_code}, type: "saveCreate"}));
                this.selectedProduct.push(Object.assign({}, {...element, quantity: quantity_change, total: total_change_price, unit_price: unit_price_change, proforma_invoice_item_id: orderItemId, selectedProduct: {item_id: element.item_id, item_code: element.item_code}, type: "saveCreate"}));
              } else {
                this.invoiceItems.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, proforma_invoice_item_id: orderItemId, selectedProduct: {item_id: element.item_id, item_code: element.item_code}, type: "saveCreate"}));
                this.selectedProduct.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, proforma_invoice_item_id: orderItemId, selectedProduct: {item_id: element.item_id, item_code: element.item_code}, type: "saveCreate"}));
              }
            } else {
              if (this.$route.query.type === 'copy-create') {
                this.invoiceItems.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                this.selectedProduct.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
              } else {
                let total_change_2 = element.remainingQuantity * element.unit_price;
                let total_change_3 = total_change_2 * element.line_discount / 100
                let total_change_4 = total_change_2 - total_change_3
                let total_change_price = this.numberWithCommas(total_change_4);
                let quantity_change = element["remainingQuantity"] ? element["remainingQuantity"] : element["quantity"]

                if (element.remainingQuantity) {
                  this.invoiceItems.push(Object.assign({}, {...element, quantity: quantity_change, total: total_change_price, unit_price: unit_price_change, order_item_id: orderItemId, type: "saveCreate", selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                  this.selectedProduct.push(Object.assign({}, {...element, quantity: quantity_change, total: total_change_price, unit_price: unit_price_change, order_item_id: orderItemId, type: "saveCreate", selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                } else {
                  this.invoiceItems.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, order_item_id: orderItemId, type: "saveCreate", selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                  this.selectedProduct.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, order_item_id: orderItemId, type: "saveCreate", selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                }
              }
            }
          } else {
            if (this.fromDelivery && !this.$route.params.invoice_id) {
              let deliveryNoteItemId;
              if (this.$route.params.copiedModel) {
                deliveryNoteItemId = element.delivery_note_item_id;
              } else {
                deliveryNoteItemId = element.id;
              }
              delete element['id'];
              delete element['delivery_note_id'];
              delete element['order_item_id'];
              delete element['delivery_note_item_id'];
              delete element['delivery_note_document_number'];
              delete element['invoice_id'];
              delete element['invoice_item_id'];
              delete element['invoice_year'];
              let unit_price_change = this.numberWithCommas(element['unit_price'], 'noFloat');
              let total_price_change = this.numberWithCommas(element['total'], 'noFloat');
              if (element['unit_price'] && !element['quantity']) {
                element = Object.assign({}, {...element, quantity: 1})
              }
              if (this.$route.query.type === 'copy-create') {
                this.invoiceItems.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change}, {selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
              } else {
                let total_change_2 = element.remainingQuantity * element.unit_price;
                let total_change_3 = total_change_2 * element.line_discount / 100
                let total_change_4 = total_change_2 - total_change_3
                let total_change_price = this.numberWithCommas(total_change_4);
                let quantity_change = element["remainingQuantity"] ? element["remainingQuantity"] : element["quantity"]

                if (element.remainingQuantity) {
                  this.invoiceItems.push(Object.assign({}, {...element, quantity: quantity_change, total: total_change_price, unit_price: unit_price_change, delivery_note_item_id: deliveryNoteItemId, type: "saveCreate"}, {selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                } else {
                  this.invoiceItems.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, delivery_note_item_id: deliveryNoteItemId, type: "saveCreate"}, {selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                }
              }
            } else {
              if (this.$route.params.copiedModel) {
                delete element['id'];
              }
              if (this.model.is_multiple) {
                if (this.model.multiple_delivery || this.model.multiple_proforma || this.isMultiple) {
                  let invoiceItemsData = [];
                  if (element.items.length > 0) {
                    element.items.forEach((itemElement) => {
                      if (this.$route.params.copiedModel) {
                        delete itemElement['id'];
                        delete itemElement['invoice_id'];
                        delete itemElement['invoice_item_id'];
                        delete itemElement['invoice_year'];
                      }
                      delete itemElement['created_at'];
                      delete itemElement['updated_at'];
                      let unit_price_change = this.numberWithCommas(itemElement['unit_price'], 'noFloat');
                      let total_price_change = this.numberWithCommas(itemElement['total'], 'noFloat');
                      if (itemElement['unit_price'] && !itemElement['quantity']) {
                        itemElement = Object.assign({}, {...itemElement, quantity: 1})
                      }
                      invoiceItemsData.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
                      this.selectedProduct.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
                    });
                  }
                  delete element['items'];
                  this.invoiceItems.push({...element, invoiceItems: invoiceItemsData});
                } else {
                  if (element.items.length > 0) {
                    element.items.forEach((itemElement) => {
                      if (this.$route.params.copiedModel) {
                        delete itemElement['id'];
                        delete itemElement['invoice_id'];
                        delete itemElement['invoice_item_id'];
                        delete itemElement['invoice_year'];
                      }
                      delete itemElement['created_at'];
                      delete itemElement['updated_at'];
                      let unit_price_change = this.numberWithCommas(itemElement['unit_price'], 'noFloat');
                      let total_price_change = this.numberWithCommas(itemElement['total'], 'noFloat');
                      if (itemElement['unit_price'] && !itemElement['quantity']) {
                        itemElement = Object.assign({}, {...itemElement, quantity: 1})
                      }
                      this.invoiceItems.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
                      this.selectedProduct.push(Object.assign({}, {...itemElement, total: total_price_change, unit_price: unit_price_change, selectedProduct: {item_id: itemElement.item_id, item_code: itemElement.item_code}}));
                    });
                  }
                }
              } else {
                let unit_price_change = this.numberWithCommas(element['unit_price'], 'noFloat');
                let total_price_change = this.numberWithCommas(element['total'], 'noFloat');
                if (element['unit_price'] && !element['quantity']) {
                  element = Object.assign({}, {...element, quantity: 1})
                }
                this.invoiceItems.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, index: index}, {selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                this.selectedProduct.push(Object.assign({}, {...element, total: total_price_change, unit_price: unit_price_change, index: index}, {selectedProduct: {item_id: element.item_id, item_code: element.item_code}}));
                if (this.$route.query.type === 'copy-create') {
                  this.invoiceItems.forEach((ele) => {
                    delete ele.proforma_invoice_item_id
                    delete ele.proforma_invoice_id;
                  })
                }
              }
            }
          }
        });
        // get and set remaining order item in invoice
        if (!this.isFieldReadable && this.fromOrder && this.$route.params.copiedModel) {
          let remainingOrderItem = this.orderById.orderItems.filter(orderItem => !this.invoiceItems.some(invoiceItem => invoiceItem.order_item_id && orderItem.id === invoiceItem.order_item_id))

          remainingOrderItem.forEach(item => {
            let orderDataItemId = item.id;
            delete item['created_at'];
            delete item['updated_at'];
            delete item['quotation_item_id'];
            delete item['order_id'];
            delete item['remainder'];
            delete item['id'];
            delete item['order_item_id'];
            let unit_price_change = this.numberWithCommas(item['unit_price'], 'noFloat');
            let total_price_change = this.numberWithCommas(item['total'], 'noFloat');
            if (item['unit_price'] && !item['quantity']) {
              item = Object.assign({}, {...item, quantity: 1})
            }
            this.invoiceItems.push(Object.assign({}, {...item, total: total_price_change, unit_price: unit_price_change, order_item_id: orderDataItemId, selectedProduct: {item_id: item.item_id, item_code: item.item_code}}));
          })
        }
        if (this.model.order_id || this.model.proforma_invoice_id) {
          if(this.invoiceItems.length == this.selectedProduct.length) {
            this.allSelectedProduct = true;
          }
        }
        if (this.fromOrder && !this.$route.params.invoice_id) {
          this.model.total = 0.00;
          this.model.discount = 0.00;
          this.model.after_discount = 0.00;
          this.model.vat = 0.00;
          this.model.grand_total = 0.00;
        }
        if (!this.$route.params.invoice_id) {
          this.model.accounting_update = false
          this.changePaymentDate(this.customerById);
        }
        if (this.model.status === 0) {
          this.$refs.form?.validate()
          if (!this.model.grand_total_requestd) {
            this.invoicePriceCalculation();
          }
        }
      } else {
        this.model.invoice_year = new Date().getFullYear();
        this.model.invoice_date = new Date().toISOString().substring(0,10);
        this.model.payment_date = new Date().toISOString().substring(0,10);
        this.selectedGroup = null;
        this.selectedWarehouse = null;
        this.model.status_one = null;
        this.model.warehouse = null;
      }
      this.$store.dispatch('profile/GetCompanyById');
      this.saveOnlyCategoryStatus = false;
      let tableNameValue = await getAuxiliaryZtableValueByName(14);
      if (Object.keys(tableNameValue).length > 0) {
        this.groupValue = tableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    },
    changeCategoryStatus() {
      if(this.originModel.status === 1) {
        this.saveOnlyCategoryStatus = true;
      }
    },
    async documentNextAndPrevious(customerId = null) {
      if (this.invoiceId) {
        let docPagination = await this.$store.dispatch('GetDocumentPagination', {type: 'invoices', customer_id: customerId, document_id: Number(this.invoiceId)});
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    async documentNextAndPreviousBlur(customerId = null) {
      let self = this;
      setTimeout(async () => {
        if (self.invoiceId) {
          let docPagination = await self.$store.dispatch('GetDocumentPagination', {type: 'invoices', customer_id: customerId, document_id: Number(self.invoiceId)});
          self.nextId = docPagination.nextId ? docPagination.nextId : null;
          self.prevId = docPagination.prevId ? docPagination.prevId : null;
        }
      }, 1000);
    },
    async infiniteScrollOnContact([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.contactLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    redirectOnNextAndPrevious(id) {
      if(id) {
        this.$router.push({ path: '/invoice/InvoiceAction/' + id})
      }
      else {return false}
    },
    redirectOnEditCustomerPage() {
      if(this.selectedCustomer){
        this.$router.push({ path: '/customer/CustomerAction/' + this.selectedCustomer.id})
      }
      else {return false;}
    },
    redirectOnEditEmployeePage() {
      if(this.selectedEmployee && this.selectedEmployee.id){
        this.$router.push({ path: '/employee/EmployeeAction/' + this.selectedEmployee.id})
      }
      else {return false;}
    },
    redirectOnEditProductPage(itemId) {
			if(itemId){
				this.$router.push({ path: '/item/ItemAction/' + itemId})
			}
			else {return false;}
		},
    callBackFunction() {
      this.$router.push({
        name: "Invoice",
        query: {
          ...this.invoiceQuery,
        },
      });
    },
    copyInvoice() {
      this.copyAndCreateDialog = true;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    redirectOnEditDeliveryPage(item) {
      if(item.related_model_id){
        this.$router.push({ path: '/delivery/deliveryAction/' + item.related_model_id})
      }
      else {return false;}
    },
    redirectOnEditOrderPage(item) {
      if(item){
        this.$router.push({ path: '/order/orderAction/' + item.related_model_id})
      }
      else{return false;}
    },
    redirectOnEditProformaPage(item) {
      if(item){
        this.$router.push({ path: '/proforma/proformaAction/' + item.related_model_id})
      }
      else{return false;}
    },
    redirectOnEditQuotationPage(item) {
      if(item){
        this.$router.push({ path: '/quotation/quotationAction/' + item.related_model_id})
      }
      else{return false;}
    },
    redirectOnEditReceiptPage(item) {
      if(item){
        this.$router.push({ path: '/receipt/receiptAction/' + item.related_model_id})
      }
      else{return false;}
    },
    redirectOnEditCreditInvoicePage(item) {
      if(item){
        this.$router.push({ path: '/creditInvoice/creditInvoiceAction/' + item.related_model_id})
      }
      else{return false;}
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, '') : null;
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : x;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = {id: item.id, name: item?.name};
        this.model.employee_id = item.id;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    onContactChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedContact = {id: item.id, name: item?.name};
        this.model.contact_id = item.id;
      } else {
        this.model.contact_id = null;
      }
    },
    onInvoiceDateChange() {
      this.changePaymentDate(this.customerById);
    },
    openCustomerAdditionalDetailModel() {
      this.customerAdditionalDetail.authorized_dealer_no = this.model.authorized_dealer_no;
      this.customerAdditionalDetail.email = this.model.email;
      this.customerAdditionalDetail.country = this.model.country;
      this.customerAdditionalDetail.phone_1 = this.model.phone_1;
      this.customerAdditionalDetail.phone_2 = this.model.phone_2;
      this.customerAdditionalDetail.cellular = this.model.cellular;
      this.customerAdditionalDetail.fax = this.model.fax;
      this.customerDetailDialog = true;
    },
    async saveCustomerAdditionalDetail() {
      this.model.authorized_dealer_no = this.customerAdditionalDetail.authorized_dealer_no;
      this.model.email = this.customerAdditionalDetail.email;
      this.model.country = this.customerAdditionalDetail.country;
      this.model.phone_1 = this.customerAdditionalDetail.phone_1;
      this.model.phone_2 = this.customerAdditionalDetail.phone_2;
      this.model.cellular = this.customerAdditionalDetail.cellular;
      this.model.fax = this.customerAdditionalDetail.fax;
      if (this.$route.params.invoice_id) {
        this.isProgressCircular = true;
        await this.$store.dispatch('invoice/EditInvoice', {invoice_id: this.$route.params.invoice_id, invoice: {...this.customerAdditionalDetail, main_number: 18, order_model_number: 15, deliveryNote_model_number: 30, quotation_model_number: 14}})
        this.isProgressCircular = false;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && String(v).length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    changePaymentDate(customer) {
      if(customer?.payment_terms_id) {
        if(!customer?.paymentTerms?.current && customer?.paymentTerms?.days && !customer?.paymentTerms?.months) {
          // for days only #!current && !months && days
          let paymentDate = new Date(this.model.invoice_date);
          let days = Number(customer?.paymentTerms?.days);
          paymentDate.setDate(paymentDate.getDate() + days);
          this.model.payment_date = paymentDate.toLocaleDateString('sv-SE')
        } else if(!customer?.paymentTerms?.current && customer?.paymentTerms?.months && !customer?.paymentTerms?.days) {
          // for months only #!current && !days && months
          let paymentDate = new Date(this.model.invoice_date);
          let paymentDay = paymentDate.getDate();
          let months = Number(customer?.paymentTerms?.months);
          paymentDate.setMonth(paymentDate.getMonth() + months);
          if (paymentDate.getDate() != paymentDay) {
            paymentDate.setDate(0);
          }
          this.model.payment_date = paymentDate.toLocaleDateString('sv-SE')
        } else if(!customer?.paymentTerms?.days && customer?.paymentTerms?.current && customer?.paymentTerms?.months) {
          // for current and months only #current && months && !days
          let paymentDate = new Date(this.model.invoice_date);
          let paymentDay = paymentDate.getDate();
          let months = Number(customer?.paymentTerms?.months);
          paymentDate.setMonth(paymentDate.getMonth() + months);
          if (paymentDate.getDate() != paymentDay) {
            paymentDate.setDate(0);
          }
          const lastDate = new Date(paymentDate.getFullYear(), paymentDate.getMonth() + 1, 0);
          this.model.payment_date = lastDate.toLocaleDateString('sv-SE')
        } else if(!customer?.paymentTerms?.months && customer?.paymentTerms?.current && customer?.paymentTerms?.days) {
          // for current and days only #current && days && !months
          let paymentDate = new Date(this.model.invoice_date);
          let days = Number(customer?.paymentTerms?.days);
          const lastDate = new Date(paymentDate.getFullYear(), paymentDate.getMonth() + 1, 0);
          lastDate.setDate(lastDate.getDate() + days);
          this.model.payment_date = lastDate.toLocaleDateString('sv-SE');
        } else if(customer?.paymentTerms?.current && customer?.paymentTerms?.days && customer?.paymentTerms?.months) {
          // for current, months and days only #current && days && months
          let paymentDate = new Date(this.model.invoice_date);
          let paymentDay = paymentDate.getDate();
          let days = Number(customer?.paymentTerms?.days);
          let months = Number(customer?.paymentTerms?.months);
          paymentDate.setMonth(paymentDate.getMonth() + months);
          if (paymentDate.getDate() != paymentDay) {
            paymentDate.setDate(0);
          }
          const lastDate = new Date(paymentDate.getFullYear(), paymentDate.getMonth() + 1, 0);
          lastDate.setDate(lastDate.getDate() + days);
          this.model.payment_date = lastDate.toLocaleDateString('sv-SE');
        } else if(!customer?.paymentTerms?.current && customer?.paymentTerms?.days && customer?.paymentTerms?.months) {
          // for months and days only #!current && days && months
          let paymentDate = new Date(this.model.invoice_date);
          let paymentDay = paymentDate.getDate();
          let days = Number(customer?.paymentTerms?.days);
          let months = Number(customer?.paymentTerms?.months);
          paymentDate.setMonth(paymentDate.getMonth() + months);
          if (paymentDate.getDate() != paymentDay) {
            paymentDate.setDate(0);
          }
          paymentDate.setDate(paymentDate.getDate() + days);
          this.model.payment_date = paymentDate.toLocaleDateString('sv-SE');
        } else if(customer?.paymentTerms?.current && !customer?.paymentTerms?.days && !customer?.paymentTerms?.months) {
          // for current only #current && !days && !months
          let paymentDate = new Date(this.model.invoice_date);
          const lastDate = new Date(paymentDate.getFullYear(), paymentDate.getMonth() + 1, 0);
          this.model.payment_date = lastDate.toLocaleDateString('sv-SE');
        } else {
          this.model.payment_date = this.model.invoice_date
        } 
      } else {
        this.model.payment_date = this.model.invoice_date
      }
    },
    async onCustomerChange(customer) {
      if (customer && typeof customer === "object" && Object.keys(customer).length > 0) {
        let customerModel = {};
        this.selectedCustomer = {id: customer.id, name_1: customer?.name_1, fixed_discount: customer?.fixed_discount};
        this.selectedContact = null;
        this.model.contact_id = null;
        this.model.customer_id = customer.id;
        if (customer.vat_charge) {
          this.model.vat_percentage = this.profileById.vat_percentage;
        } else {
          this.model.vat_percentage = '';
          this.invoicePriceCalculation();
        }
        if(this.model.customer_id) {
          await this.$store.dispatch('customer/GetCustomerById', this.model.customer_id)
        }
        this.customer_id = customer.id;
        if (this.profileById.occasional_customer_no !== String(customer.id)) {
          this.model.customer_name = customer?.name_1;
          if (customer.employee_id) {
            customer.employee_id ? await this.$store.dispatch('employee/GetEmployeeById', customer.employee_id) : '';
            this.selectedEmployee = {id: this.employeeById.id, name: this.employeeById.name};
            this.model.employee_id = customer.employee_id;
          } else {
            this.selectedEmployee = this.user.name
          }
          customerModel.authorized_dealer_no = customer.authorized_dealer_no;
          customerModel.email = customer.email;
          customerModel.address = customer.address_1;
          customerModel.town = customer.town_1;
          customerModel.country = customer.country;
          customerModel.phone_1 = customer.phone_1;
          customerModel.phone_2 = customer.phone_2;
          customerModel.cellular = customer.cellular_1;
          customerModel.fax = customer.fax;
          this.invoicePriceCalculation();
        } else {
          this.model.customer_name = '';
          customerModel.authorized_dealer_no = '';
          customerModel.email = '';
          customerModel.address = '';
          customerModel.town = '';
          customerModel.country = '';
          customerModel.phone_1 = '';
          customerModel.phone_2 = '';
          customerModel.cellular = '';
          customerModel.fax = '';
        }
        this.changePaymentDate(customer);
        this.includeVatCharge = customer.vat_charge;
        this.customerOrderNoRequired = customer.customer_order_no_compulsory;
        this.model = Object.assign({}, this.model, customerModel);
        if (this.model.status === 0) {
          this.$refs.form.validate()
        }
      } else {
        this.selectedCustomer = null;
        this.model.customer_id = null;
      }
      this.invoicePriceCalculation();
    },
    selectedInvoiceProduct() {
      if (this.selectedProduct && this.invoiceItems.filter(ab => ab.selectedProduct).length == this.selectedProduct.length) {
        this.allSelectedProduct = true;
      } else {
        if (!this.selectedProduct) {
          this.selectedProduct = [];
        }
        this.allSelectedProduct = false;
      }
    },
    checkBoxEventCalculate() {
      if (!this.selectedProduct) {
        this.selectedProduct = [];
      }
      this.invoicePriceCalculation();
    },
    selectAllOrderProduct() {
      this.selectedProduct = [];
      this.model.total = 0.00;
      if (this.allSelectedProduct) {
        for (let invoice in this.invoiceItems) {
          if (this.invoiceItems[invoice].selectedProduct) {
            this.selectedProduct.push(this.invoiceItems[invoice]);
            this.invoicePriceCalculation(); 
          }
        }
      }
      else {
        this.invoicePriceCalculation();
      }
    },
    itemPriceCalculation(index, mainIndex = null) {
      if (this.isMultiple || this.fromMultipleDelivery || this.fromMultipleProformaInvoice) {
        let item = this.invoiceItems[mainIndex].invoiceItems[index];
        let checkUnitPrice = item.unit_price;
        if (checkUnitPrice) {
          const pos = this.$refs['unit_price'+mainIndex+index][0].$el.querySelector('input').selectionStart + 1;
          if(checkUnitPrice.length != this.numberWithCommas(this.numberFromCommas(item.unit_price), 'noFloat').length) {
            this.$nextTick(() => {
              this.$refs['unit_price'+mainIndex+index][0]?.$el ? this.$refs['unit_price'+mainIndex+index][0].$el.querySelector('input').selectionEnd = pos : '';
            })
          } else {
            this.$nextTick(() => {
              this.$refs['unit_price'+mainIndex+index][0]?.$el ? this.$refs['unit_price'+mainIndex+index][0].$el.querySelector('input').selectionEnd = pos-1 : '';
            })
          }
        }
        
        // price format of total price value
        let checkTotal = item.total;
        if (checkTotal) {
          const pos = this.$refs['total_price'+mainIndex+index][0].$el.querySelector('input').selectionStart + 1;
          if(checkTotal.length != this.numberWithCommas(this.numberFromCommas(item.total), 'noFloat').length) {
            this.$nextTick(() => {
              this.$refs['total_price'+mainIndex+index][0]?.$el ? this.$refs['total_price'+mainIndex+index][0].$el.querySelector('input').selectionEnd = pos : '';
            })
          } else {
            this.$nextTick(() => {
              this.$refs['total_price'+mainIndex+index][0]?.$el ? this.$refs['total_price'+mainIndex+index][0].$el.querySelector('input').selectionEnd = pos-1 : '';
            })
          }
        }

        this.invoiceItems[mainIndex].invoiceItems[index].unit_price = this.numberWithCommas(this.numberFromCommas(item.unit_price), 'noFloat');
        this.invoiceItems[mainIndex].invoiceItems[index].total = this.numberWithCommas(
          this.numberFromCommas(item.total),
          "noFloat"
        );
        this.$nextTick(() => {
          if(this.$refs.form && this.$refs['unit_price'+mainIndex+index][0].validate()) {
            item.unit_price = this.numberFromCommas(item.unit_price);
          }
          if (this.$refs.form && this.$refs["total_price" + mainIndex+index][0].validate()) {
            item.total = this.numberFromCommas(item.total);
          }
          if (parseFloat(item.unit_price)) {
            item.total = parseFloat(item.quantity) && parseFloat(item.unit_price) ? (parseFloat(item.quantity) * parseFloat(item.unit_price)).toFixed(2) : parseFloat(item.unit_price) ? parseFloat(item.unit_price).toFixed(2) : (0).toFixed(2);

            // item total calculation with discount
            item.total = parseFloat(item.line_discount) ? (parseFloat(item.total) - (parseFloat(item.total) *(parseFloat(item.line_discount))/100)).toFixed(2) : parseFloat(item.total).toFixed(2);

            this.invoiceItems[mainIndex].invoiceItems[index].unit_price = this.numberWithCommas(this.numberFromCommas(item.unit_price), 'noFloat');

            this.invoiceItems[mainIndex].invoiceItems[index].total = this.numberWithCommas(this.numberFromCommas(item.total), 'noFloat');

            if (this.invoiceItems[mainIndex].invoiceItems[index].unit_price && !item.quantity) {
              item.quantity = 1
            } else {
              item.quantity = !this.invoiceItems[mainIndex].invoiceItems[index].unit_price ? null : item.quantity
            }
          } else {
            this.invoiceItems[mainIndex].invoiceItems[index].total = this.numberWithCommas(
              this.numberFromCommas(item.total),
              "noFloat"
            );
          }
          this.invoiceItems[mainIndex].invoiceItems[index] = item;

          // total calculation on quotation
          this.invoicePriceCalculation();
        })
      } else {
        let item = this.invoiceItems[index];
        let checkUnitPrice = item.unit_price;
        if (checkUnitPrice && this.$refs['unit_price'+index]) {
          const pos = this.$refs['unit_price'+index][0].$el.querySelector('input').selectionStart + 1;
          if(checkUnitPrice.length != this.numberWithCommas(this.numberFromCommas(item.unit_price), 'noFloat').length) {
            this.$nextTick(() => {
              this.$refs['unit_price'+index][0]?.$el ? this.$refs['unit_price'+index][0].$el.querySelector('input').selectionEnd = pos : '';
            })
          } else {
            this.$nextTick(() => {
              this.$refs['unit_price'+index][0]?.$el ? this.$refs['unit_price'+index][0].$el.querySelector('input').selectionEnd = pos-1 : '';
            })
          }
        }

        // price format of total price value
        let checkTotal = item.total;
        if (checkTotal && this.$refs['total_price'+index]) {
          const pos = this.$refs['total_price'+index][0].$el.querySelector('input').selectionStart + 1;
          if(checkTotal.length != this.numberWithCommas(this.numberFromCommas(item.total), 'noFloat').length) {
            this.$nextTick(() => {
              this.$refs['total_price'+index][0]?.$el ? this.$refs['total_price'+index][0].$el.querySelector('input').selectionEnd = pos : '';
            })
          } else {
            this.$nextTick(() => {
              this.$refs['total_price'+index][0]?.$el ? this.$refs['total_price'+index][0].$el.querySelector('input').selectionEnd = pos-1 : '';
            })
          }
        }
        this.invoiceItems[index].unit_price = this.numberWithCommas(this.numberFromCommas(item.unit_price), 'noFloat');
        this.invoiceItems[index].total = this.numberWithCommas(this.numberFromCommas(item.total), 'noFloat');
        this.$nextTick(() => {
          if(this.$refs.form && this.$refs['unit_price'+index][0].validate()) {
            item.unit_price = this.numberFromCommas(item.unit_price);
          }
          if(this.$refs.form && this.$refs['total_price'+index][0].validate()) {
            item.total = this.numberFromCommas(item.total);
          }
          if (parseFloat(item.unit_price)) {
            item.total = parseFloat(item.quantity) && parseFloat(item.unit_price) ? (parseFloat(item.quantity) * parseFloat(item.unit_price)).toFixed(2) : parseFloat(item.unit_price) ? parseFloat(item.unit_price).toFixed(2) : (0).toFixed(2);

            // item total calculation with discount
            item.total = parseFloat(item.line_discount) ? (parseFloat(item.total) - (parseFloat(item.total) *(parseFloat(item.line_discount))/100)).toFixed(2) : parseFloat(item.total).toFixed(2);

            this.invoiceItems[index].unit_price = this.numberWithCommas(this.numberFromCommas(item.unit_price), 'noFloat');

            this.invoiceItems[index].total = this.numberWithCommas(this.numberFromCommas(item.total), 'noFloat');
            
            if (this.invoiceItems[index].unit_price && !item.quantity) {
              item.quantity = 1
            } 
          } else {
            this.invoiceItems[index].total = this.numberWithCommas(this.numberFromCommas(item.total), 'noFloat');
          }

          this.invoiceItems[index] = item;

          // total calculation on quotation
          this.invoicePriceCalculation();
        })
      }
    },
    grandTotalRequested() {
      if (this.model.grand_total && this.model.grand_total_requestd) {
        // after_discount and vat
        this.model.after_discount = (this.model.grand_total_requestd / ((this.model.vat_percentage / 100) + 1)).toFixed(2);
        this.model.vat = (this.model.grand_total_requestd - this.model.after_discount).toFixed(2);
        // grand_total
        this.model.grand_total = this.model.grand_total_requestd
        // discount and discount_percentage
        if (Math.abs(this.model.discount) < 1) {
          this.model.discount = 0.00;
          this.model.discount_percentage = '';
        } else {
          this.model.discount = (parseFloat(this.model.total) - parseFloat(this.model.after_discount))
          this.model.discount_percentage = ((parseFloat(this.model.discount) / parseFloat(this.model.total)) * 100).toFixed(2);
        }
      } else {
        this.invoicePriceCalculation();
      }
    },
    invoicePriceCalculation() {
      let self = this;
      if (this.fromOrder && !this.$route.params.invoice_id || this.model.proforma_invoice_id && !this.$route.params.invoice_id) {
        if (this.orderById.including_vat || this.proformaById.including_vat) {
          this.model.total = this.selectedProduct && this.selectedProduct.length > 0 ? this.selectedProduct.reduce(function(total, item){
            return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0;
          },0).toFixed(2) / (self.model.vat_percentage / 100 + 1) : 0;
        } else {
          this.model.total = this.selectedProduct && this.selectedProduct.length > 0 ? this.selectedProduct.reduce(function(total, item){
            return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0;
          },0).toFixed(2) : 0;
        }
        // discount amount calculation
        this.model.discount_percentage = parseFloat(this.model.discount_percentage) ? parseFloat(this.model.discount_percentage).toFixed(2) : null;
        this.model.discount = parseFloat(this.model.discount_percentage) ? (parseFloat(this.model.total) *(parseFloat(this.model.discount_percentage))/100).toFixed(2) : (0).toFixed(2);

        // total amount after discount calculation
        this.model.after_discount = parseFloat(this.model.discount) ? (parseFloat(this.model.total) - parseFloat(this.model.discount)).toFixed(2) : (parseFloat(this.model.total)).toFixed(2);

        this.model.vat_percentage = parseFloat(this.model.vat_percentage) ? parseFloat(this.model.vat_percentage) : null;
        this.model.vat = parseFloat(this.model.vat_percentage) && this.includeVatCharge ? (parseFloat(this.model.after_discount) *(parseFloat(this.model.vat_percentage))/100).toFixed(2) : (0).toFixed(2);

        // grand total amount calculation
        this.model.grand_total = parseFloat(this.model.vat) ? (parseFloat(this.model.after_discount) + parseFloat(this.model.vat)).toFixed(2) : (parseFloat(this.model.after_discount)).toFixed(2);

        // after vat grand total rounding if total rounding is true
        if(this.profileById.total_rounding) {
          let total = this.model.total;
          let discount = this.model.discount;
          let vatPercentage = this.model.vat_percentage;
          let grandTotal = parseInt(this.model.grand_total);
          let roundingDiff = this.model.grand_total - grandTotal;
          let findRoundNumber = parseFloat(roundingDiff/(1+(vatPercentage/100))).toFixed(2);
          if (findRoundNumber <= 0.5) {
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (parseFloat(discount) + parseFloat(findRoundNumber)).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
            // calculate vat
            this.model.vat = (parseFloat(grandTotal) - parseFloat(this.model.after_discount)).toFixed(2);
            // calculate grand_total
            this.model.grand_total = parseFloat(grandTotal).toFixed(2);
          } else {
            let roundingDiff = parseFloat(((grandTotal + 1)-this.model.grand_total) * (-1)).toFixed(2);
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (parseFloat(discount) + (parseFloat(roundingDiff/(1+(vatPercentage/100))))).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
            // calculate vat
            this.model.vat = ((parseFloat(grandTotal) + 1) - parseFloat(this.model.after_discount)).toFixed(2);
            // calculate grand_total
            this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
          }
        }
      } else if (this.$route.query.quotationId || this.$route.query.proformaId || this.$route.query.receiptTaxInvoiceId || this.$route.query.creditInvoiceId || this.$route.query.returnCertificateId) { 
        if (this.quotationById.including_vat || this.proformaById.including_vat || this.receiptTaxInvoiceById.including_vat || this.creditInvoiceById.including_vat || this.returnCertificateById.including_vat) {
          this.model.total = this.selectedProduct && this.selectedProduct.length > 0 ? this.selectedProduct.reduce(function(total, item){
            return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0;
          },0).toFixed(2) / (self.model.vat_percentage / 100 + 1) : 0;
        } else {
          this.model.total = this.selectedProduct && this.selectedProduct.length > 0 ? this.selectedProduct.reduce(function(total, item){
            return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0;
          },0).toFixed(2) : 0;
        }
        // discount amount calculation
        this.model.discount_percentage = parseFloat(this.model.discount_percentage) ? parseFloat(this.model.discount_percentage).toFixed(2) : null;
        this.model.discount = parseFloat(this.model.discount_percentage) ? (parseFloat(this.model.total) *(parseFloat(this.model.discount_percentage))/100).toFixed(2) : (0).toFixed(2);

        // total amount after discount calculation
        this.model.after_discount = parseFloat(this.model.discount) ? (parseFloat(this.model.total) - parseFloat(this.model.discount)).toFixed(2) : (parseFloat(this.model.total)).toFixed(2);

        this.model.vat_percentage = parseFloat(this.model.vat_percentage) ? parseFloat(this.model.vat_percentage) : null;
        this.model.vat = parseFloat(this.model.vat_percentage) && this.includeVatCharge ? (parseFloat(this.model.after_discount) *(parseFloat(this.model.vat_percentage))/100).toFixed(2) : (0).toFixed(2);

        // grand total amount calculation
        this.model.grand_total = parseFloat(this.model.vat) ? (parseFloat(this.model.after_discount) + parseFloat(this.model.vat)).toFixed(2) : (parseFloat(this.model.after_discount)).toFixed(2);

        // after vat grand total rounding if total rounding is true
        if(this.profileById.total_rounding) {
          let total = this.model.total;
          let discount = this.model.discount;
          let vatPercentage = this.model.vat_percentage;
          let grandTotal = parseInt(this.model.grand_total);
          let roundingDiff = this.model.grand_total - grandTotal;
          let findRoundNumber = parseFloat(roundingDiff/(1+(vatPercentage/100))).toFixed(2);
          if (findRoundNumber <= 0.5) {
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (parseFloat(discount) + parseFloat(findRoundNumber)).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
            // calculate vat
            this.model.vat = (parseFloat(grandTotal) - parseFloat(this.model.after_discount)).toFixed(2);
            // calculate grand_total
            this.model.grand_total = parseFloat(grandTotal).toFixed(2);
          } else {
            let roundingDiff = parseFloat(((grandTotal + 1)-this.model.grand_total) * (-1)).toFixed(2);
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (parseFloat(discount) + (parseFloat(roundingDiff/(1+(vatPercentage/100))))).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
            // calculate vat
            this.model.vat = ((parseFloat(grandTotal) + 1) - parseFloat(this.model.after_discount)).toFixed(2);
            // calculate grand_total
            this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
          }
        }
      } else {
        // totol item's calculation
        let self = this;
        if (this.isMultiple || this.fromMultipleDelivery || this.fromMultipleProformaInvoice) {
          // total item calculation from multiple order and DN
          if (this.invoiceItems.length > 0) {
            let totalItems = 0;
            this.invoiceItems.forEach(order => {
              totalItems += order.invoiceItems.length > 0 ? order.invoiceItems.reduce(function(total, item){
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0;
              },0) : 0;
            })
            if ((!this.invoiceId && this.profileById.invoice && this.includeVatCharge) || (this.invoiceId && this.model.including_vat)) {
              this.model.total = (totalItems).toFixed(2) / (self.model.vat_percentage / 100 + 1);
            } else {
              this.model.total = (totalItems).toFixed(2);
            }
          } else {
            this.model.total = 0;
          }
        } else {
          if (!this.$route.params.copiedModel && !this.$route.query.deliveryId) {
            if ((!this.invoiceId && this.profileById.invoice && this.includeVatCharge) || (this.invoiceId && this.model.including_vat)) {
              this.model.total = this.invoiceItems.length > 0 ? this.invoiceItems.reduce(function(total, item){
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0; 
              },0).toFixed(2) / (self.model.vat_percentage / 100 + 1) : 0;
            } else {
              this.model.total = this.invoiceItems.length > 0 ? this.invoiceItems.reduce(function(total, item){
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0; 
              },0).toFixed(2) : 0;
            }
          } else if (this.$route.query.deliveryId) {
            if (this.deliveryById.including_vat) {
              this.model.total = this.invoiceItems.length > 0 ? this.invoiceItems.reduce(function(total, item){
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0; 
              },0).toFixed(2) / (self.model.vat_percentage / 100 + 1) : 0;
            } else {
              this.model.total = this.invoiceItems.length > 0 ? this.invoiceItems.reduce(function(total, item){
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0; 
              },0).toFixed(2) : 0;
            }
          } else {
            if (this.model.including_vat) {
              this.model.total = this.invoiceItems.length > 0 ? this.invoiceItems.reduce(function(total, item){
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0; 
              },0).toFixed(2) / (self.model.vat_percentage / 100 + 1) : 0;
            } else {
              this.model.total = this.invoiceItems.length > 0 ? this.invoiceItems.reduce(function(total, item){
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0; 
              },0).toFixed(2) : 0;
            }
          }
        }

        // discount amount calculation
        this.model.discount_percentage = parseFloat(this.model.discount_percentage) ? parseFloat(this.model.discount_percentage).toFixed(2) : null;
        this.model.discount = parseFloat(this.model.discount_percentage) ? (parseFloat(this.model.total) *(parseFloat(this.model.discount_percentage))/100).toFixed(2) : (0).toFixed(2);

        // total amount after discount calculation
        this.model.after_discount = parseFloat(this.model.discount) ? (parseFloat(this.model.total) - parseFloat(this.model.discount)).toFixed(2) : (parseFloat(this.model.total)).toFixed(2);

        this.model.vat_percentage = parseFloat(this.model.vat_percentage) ? parseFloat(this.model.vat_percentage) : null;
        this.model.vat = parseFloat(this.model.vat_percentage) && this.includeVatCharge ? (parseFloat(this.model.after_discount) *(parseFloat(this.model.vat_percentage))/100).toFixed(2) : (0).toFixed(2);

        // grand total amount calculation
        this.model.grand_total = parseFloat(this.model.vat) ? (parseFloat(this.model.after_discount) + parseFloat(this.model.vat)).toFixed(2) : (parseFloat(this.model.after_discount)).toFixed(2);

        // after vat grand total rounding if total rounding is true
        if(this.profileById.total_rounding) {
          let total = this.model.total;
          let discount = this.model.discount;
          let vatPercentage = this.model.vat_percentage;
          let grandTotal = parseInt(this.model.grand_total);
          let roundingDiff = this.model.grand_total - grandTotal;
          let findRoundNumber = parseFloat(roundingDiff/(1+(vatPercentage/100))).toFixed(2);
          if (findRoundNumber <= 0.5) {
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (parseFloat(discount) + parseFloat(findRoundNumber)).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
            // calculate vat
            this.model.vat = (parseFloat(grandTotal) - parseFloat(this.model.after_discount)).toFixed(2);
            // calculate grand_total
            this.model.grand_total = parseFloat(grandTotal).toFixed(2);
          } else {
            let roundingDiff = parseFloat(((grandTotal + 1)-this.model.grand_total) * (-1)).toFixed(2);
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (parseFloat(discount) + (parseFloat(roundingDiff/(1+(vatPercentage/100))))).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
            // calculate vat
            this.model.vat = ((parseFloat(grandTotal) + 1) - parseFloat(this.model.after_discount)).toFixed(2);
            // calculate grand_total
            this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
          }
        }
      }
    },
    addRow() {
      this.invoiceItems.push({
        index: this.invoiceItems.length,
        selectedProduct: null,
        unit_price: null,
        item_description: null,
        total: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(".item-table div.v-data-table__wrapper")
      this.$vuetify.goTo(newRow, {container: wrapper});
    },
    copyData(i) {	
      delete this.invoiceItems[i].id;	
      delete this.invoiceItems[i].invoice_id;	
      delete this.invoiceItems[i].created_at;	
      delete this.invoiceItems[i].updated_at;	
      delete this.invoiceItems[i].order_id;	
      delete this.invoiceItems[i].delivery_note_id;	
      delete this.invoiceItems[i].proforma_invoice_id;	
      delete this.invoiceItems[i].invoice_year;	
      delete this.invoiceItems[i].line_number;	
      delete this.invoiceItems[i].quotation_id;	
      delete this.invoiceItems[i].invoice_item_id;	
      delete this.invoiceItems[i].order_item_id;	
      delete this.invoiceItems[i].delivery_note_item_id;	
      delete this.invoiceItems[i].delivery_note_document_number;
      delete this.invoiceItems[i].proforma_invoice_item_id;	
      delete this.invoiceItems[i].supplied;
      this.invoiceItems.push({...this.invoiceItems[i]})	
    },
    deleteRow(index) {
      this.selectedProduct = this.selectedProduct.filter(item => item.index !== index);
      this.selectedProduct.splice(index,1);
      this.invoiceItems.splice(index,1);
      this.invoicePriceCalculation()
    },
    addRowForMultiple(indexItem, className) {
      if (this.fromMultipleProformaInvoice) {
        this.invoiceItems[indexItem].invoiceItems.push({
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
          proforma_invoice_id: this.invoiceItems[indexItem].proforma_invoice_id,
        });
      } else if (this.fromMultipleDelivery) {
        this.invoiceItems[indexItem].invoiceItems.push({
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
          delivery_note_id: this.invoiceItems[indexItem].delivery_note_id,
        });
      } else {
        this.invoiceItems[indexItem].invoiceItems.push({
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
          order_id: this.invoiceItems[indexItem].order_id,
        });
      }
      // scroll on new added row
      let newRow = document.querySelector(`.${className}`);
      let wrapper = document.querySelector(".item-table div.v-data-table__wrapper")
      this.$vuetify.goTo(newRow, {container: wrapper});
    },
    deleteRowForMultiple(indexItem, index) {
      this.invoiceItems[indexItem].invoiceItems.splice(index,1);
      this.invoicePriceCalculation()
    },
    async onProductChange(item, index, type, mainDocIndexForMultiple = null) {
      if (mainDocIndexForMultiple !== null || this.mainDocIndexForMultiple !== null) {
        let mainIndex = mainDocIndexForMultiple !== null ? mainDocIndexForMultiple : this.mainDocIndexForMultiple !== null ? this.mainDocIndexForMultiple : null;
        if (mainIndex !== null && type === 'dropdown') {
          this.invoiceItems[mainIndex].invoiceItems[index].index = index;
          this.invoiceItems[mainIndex].invoiceItems[index].selectedProduct = {item_id: item.id, item_code: item.item_code}

          if (!item?.customerPrice) {
            this.invoiceItems[mainIndex].invoiceItems[index].unit_price = item.unit_price;
          }

          this.invoiceItems[mainIndex].invoiceItems[index].item_id = item.id;
          this.invoiceItems[mainIndex].invoiceItems[index].quantity = item.quantity;

          if (item.quantity) {
            if (this.selectedCustomer?.fixed_discount) {
              this.invoiceItems[mainIndex].invoiceItems[index].line_discount = this.selectedCustomer?.fixed_discount
            } else if (item.customerDiscount) {
              this.invoiceItems[mainIndex].invoiceItems[index].line_discount = item.customerDiscount
            } else if (item.customerPrice) {
              this.invoiceItems[mainIndex].invoiceItems[index].unit_price = item.customerPrice
            } else if (item.priceListDiscount) {
              this.invoiceItems[mainIndex].invoiceItems[index].line_discount = item.priceListDiscount
            } else {
              this.invoiceItems[mainIndex].invoiceItems[index].line_discount = null
            }
          } else {
            this.invoiceItems[mainIndex].invoiceItems[index].line_discount = null
          }

          this.invoiceItems[mainIndex].invoiceItems[index].item_code = item.item_code;
          this.invoiceItems[mainIndex].invoiceItems[index].item_description = item.item_description;
          this.$refs.form.validate()
          this.itemPriceCalculation(index, mainDocIndexForMultiple);
        } else {
          await this.$store.dispatch('product/GetItemDiscountOrPrice', {
            customer_id: this.selectedCustomer.id,
            item_id: item.id
          }).then(async (res) => {
            this.itemDiscount = await res
          })

          this.invoiceItems[mainIndex].invoiceItems[index].index = index;
          this.invoiceItems[mainIndex].invoiceItems[index].selectedProduct = {item_id: item.id, item_code: item.item_code}

          if (!this.itemDiscount && !this.itemDiscount.customerPrice) {
            this.invoiceItems[mainIndex].invoiceItems[index].unit_price = item.sale_price;
          }
          
          this.invoiceItems[mainIndex].invoiceItems[index].item_id = item.id;
          this.invoiceItems[mainIndex].invoiceItems[index].quantity = item.quantity;

          if (this.selectedCustomer?.fixed_discount) {
            this.invoiceItems[mainIndex].invoiceItems[index].line_discount = this.selectedCustomer?.fixed_discount
          } else if (this.itemDiscount && this.itemDiscount.customerDiscount) {
            this.invoiceItems[mainIndex].invoiceItems[index].line_discount = this.itemDiscount.customerDiscount
          } else if (this.itemDiscount && this.itemDiscount.customerPrice) {
            this.invoiceItems[mainIndex].invoiceItems[index].unit_price = this.itemDiscount.customerPrice
          } else if (this.itemDiscount && this.itemDiscount.priceListDiscount) {
            this.invoiceItems[mainIndex].invoiceItems[index].line_discount = this.itemDiscount.priceListDiscount
          } else {
            this.invoiceItems[mainIndex].invoiceItems[index].line_discount = null
          }
          
          this.invoiceItems[mainIndex].invoiceItems[index].item_code = item.item_code;
          this.invoiceItems[mainIndex].invoiceItems[index].item_description = item.description;
          this.$refs.form.validate()
          this.itemPriceCalculation(index, mainDocIndexForMultiple);
        }
      } else {
        if (item && type === 'dropdown') {
          this.invoiceItems[index].index = index;
          this.invoiceItems[index].selectedProduct = {item_id: item.id, item_code: item.item_code}

          if (!item?.customerPrice) {
            this.invoiceItems[index].unit_price = item.unit_price;
          }

          this.invoiceItems[index].item_id = item.id;
          this.invoiceItems[index].quantity = item.quantity;

          if (item.quantity) {
            if (this.selectedCustomer?.fixed_discount) {
              this.invoiceItems[index].line_discount = this.selectedCustomer?.fixed_discount
            } else if (item.customerDiscount) {
              this.invoiceItems[index].line_discount = item.customerDiscount
            } else if (item.customerPrice) {
              this.invoiceItems[index].unit_price = item.customerPrice
            } else if (item.priceListDiscount) {
              this.invoiceItems[index].line_discount = item.priceListDiscount
            } else if (item.fixed_discount) {
              this.invoiceItems[index].line_discount = item.fixed_discount
            } else {
              this.invoiceItems[index].line_discount = null
            }
          } else {
            this.invoiceItems[index].line_discount = null
          }
          
          this.invoiceItems[index].item_code = item.item_code;
          this.invoiceItems[index].item_description = item.item_description;
          this.selectedProduct[index] = this.invoiceItems[index];
          this.$refs.form.validate()
          this.itemPriceCalculation(index);
        } else {
          if (item && Object.keys(item).length > 0) {
            await this.$store.dispatch('product/GetItemDiscountOrPrice', {
              customer_id: this.selectedCustomer.id,
              item_id: item.id
            }).then(async (res) => {
              this.itemDiscount = await res
            })
            
            this.invoiceItems[index].index = index;
            this.invoiceItems[index].selectedProduct = {item_id: item.id, item_code: item.item_code}

            if (!this.itemDiscount && !this.itemDiscount.customerPrice) {
              this.invoiceItems[index].unit_price = item.sale_price;
            }
            
            this.invoiceItems[index].item_id = item.id;
            this.invoiceItems[index].quantity = null;

            if (this.selectedCustomer?.fixed_discount) {
              this.invoiceItems[index].line_discount = this.selectedCustomer?.fixed_discount
            } else if (this.itemDiscount && this.itemDiscount.customerDiscount) {
              this.invoiceItems[index].line_discount = this.itemDiscount.customerDiscount
            } else if (this.itemDiscount && this.itemDiscount.customerPrice) {
              this.invoiceItems[index].unit_price = this.itemDiscount.customerPrice
            } else if (this.itemDiscount && this.itemDiscount.priceListDiscount) {
              this.invoiceItems[index].line_discount = this.itemDiscount.priceListDiscount
            } else {
              this.invoiceItems[index].line_discount = null
            }

            this.invoiceItems[index].item_code = item.item_code;
            this.invoiceItems[index].item_description = item.description;
            this.selectedProduct[index] = this.invoiceItems[index];
            this.$refs.form.validate()
            this.itemPriceCalculation(index);
          } else {
            this.invoiceItems[index].index = index;
            this.invoiceItems[index].selectedProduct = null;
            this.invoiceItems[index].unit_price = null;
            this.invoiceItems[index].item_id = null;
            this.invoiceItems[index].quantity = null;
            this.invoiceItems[index].line_discount = null
            this.invoiceItems[index].item_code = null;
            this.invoiceItems[index].item_description = null;
            this.selectedProduct[index] = this.invoiceItems[index];
            this.$refs.form.validate()
            this.itemPriceCalculation(index);
          }
        }
      }
    },
    createReceipt() {
      this.$router.push({ path: '/addReceipt', query: { customerId: this.model.customer_id, employeeId: this.model.employee_id, invoiceData: this.originModel }})
    },
    createCreditInvoice() {
      if (this.creditInvoiceType || this.$route.params.creditInvoiceType) {
        this.$router.push({
          name: 'addCreditInvoice',
          params: {
            invoiceId: this.$route.params.invoice_id
          }
        })
      } else {
        this.$router.push({ path: '/addCreditInvoice', query: { customerId: this.model.customer_id, employeeId: this.model.employee_id, invoiceData: this.originModel }})
      }
    },
    async saveInvoice() {
      if(this.$refs.form.validate() || this.$refs.form.validate() && this.isFieldReadable && this.saveOnlyCategoryStatus){
        // open confirmation dialog for change order status
        // for update order status as closed
        if (this.fromOrder && this.setOrderStatus == null) {
          let totalSelectedItemFromOrder = this.selectedProduct.filter(item => item.order_item_id)
          if (this.$route.params.invoice_id) {
            let orderData = await this.$store.dispatch('order/GetOrderById', this.originModel.order_id);
            if (orderData.orderItems.length !== totalSelectedItemFromOrder.length) {
              this.confirmationDialog = true;
            }
          } else {
            if (this.orderById.orderItems.length !== totalSelectedItemFromOrder.length) {
              this.confirmationDialog = true;
            }
          }
        }
        if (!this.confirmationDialog) {
          this.loading = true;
          let data;
          let invoiceItems;
          if (this.fromOrder && !this.$route.params.invoice_id || this.model.proforma_invoice_id && !this.$route.params.invoice_id) {
            invoiceItems = this.selectedProduct.filter((item) => {
              if (item.id || item.item_id) {
                delete item['selectedProduct'];
                delete item['index'];
                delete item['document_number'];
                delete item['document_date'];
                delete item['customer_order_no'];
                delete item['title'];
                delete item['year'];
                item.quantity = item.quantity === '' ? null : item.quantity;
                item.line_discount = item.line_discount === '' ? null : item.line_discount;
                item.unit_price = this.numberFromCommas(item.unit_price) === '' ? null : this.numberFromCommas(item.unit_price);
                item.total = this.numberFromCommas(item.total) === '' ? null : this.numberFromCommas(item.total);
                return item;
              }
            })
          } else {
            // if (this.invoiceItems.length > 0) {
              if (this.isMultiple || this.fromMultipleDelivery || this.fromMultipleProformaInvoice) {
                let items = [];
                this.invoiceItems.filter((mainItem) => {
                  mainItem.invoiceItems.filter((item) => {
                    if (item.id || item.item_id) {
                      delete item['selectedProduct'];
                      delete item['index'];
                      delete item['year'];
                      delete item['document_number'];
                      delete item['document_date'];
                      delete item['customer_order_no'];
                      delete item['title'];
                      delete item['proforma_invoice_year'];
                      item.quantity = item.quantity === '' ? null : item.quantity;
                      item.line_discount = item.line_discount === '' ? null : item.line_discount;
                      item.unit_price = this.numberFromCommas(item.unit_price) === '' ? null : this.numberFromCommas(item.unit_price);
                      item.total = this.numberFromCommas(item.total) === '' ? null : this.numberFromCommas(item.total);
                      items.push(item);
                    }
                  })
                });
                invoiceItems = items;
              } else {
                invoiceItems = this.invoiceItems.filter((item) => {
                  if (item.id || item.item_id) {
                    delete item['selectedProduct'];
                    delete item['index'];
                    delete item['year'];
                    delete item['document_number'];
                    delete item['document_date'];
                    delete item['customer_order_no'];
                    delete item['title'];
                    item.quantity = item.quantity === '' ? null : item.quantity;
                    item.line_discount = item.line_discount === '' ? null : item.line_discount;
                    item.unit_price = this.numberFromCommas(item.unit_price) === '' ? null : this.numberFromCommas(item.unit_price);
                    item.total = this.numberFromCommas(item.total) === '' ? null : this.numberFromCommas(item.total);
                    return item;
                  }
                })
              }
            // }
          }
          delete this.model.proforma_invoice_document_number;
          delete this.model.orderStatus;
          delete this.model.deliveryNoteStatus;
          delete this.model.proformaInvoicesStatus;
          this.model.data = invoiceItems;
          delete this.model.invoiceItems;
          if (this.selectedStatus && Object.keys(this.selectedStatus).length > 0) {
            this.model.status = this.selectedStatus.id;
          } else {
            this.model.status = this.selectedStatus;
          }
          if(this.model.status == 0) {
            this.model.balance = this.model.grand_total;
          } else {
            this.model.balance = this.numberFromCommas(this.model.balance);
          }
          if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
            this.model.status_one = this.selectedGroup.id;
          }
          if (this.selectedWarehouse && Object.keys(this.selectedWarehouse).length > 0) {
            this.model.warehouse = this.selectedWarehouse.id;
          }
          if (this.model.order_id && !this.$route.params.copiedModel && !this.model.id) {
            this.model.including_vat = this.orderById.including_vat ? 1 : 0
          } else if (this.model.delivery_note_id && !this.$route.params.copiedModel && !this.model.id) {
            this.model.including_vat = this.deliveryById.including_vat ? 1 : 0
          } else if (this.model.proforma_invoice_id && !this.$route.params.copiedModel && !this.model.id) {
            this.model.including_vat = this.proformaById.including_vat ? 1 : 0
          } else if (!this.$route.params.copiedModel && !this.model.id) {
            this.model.including_vat = this.profileById.invoice ? 1 : 0
          }
          if (this.$route.params.copiedModel && !this.model.id) {
            this.model.including_vat = this.originModel.including_vat ? 1 : 0
          }
          if (!this.model.id) {
            if (this.$route.query.orderId) {
              this.model.including_vat = this.orderById.including_vat ? 1 : 0
            } else if (this.$route.query.proformaId) {
              this.model.including_vat = this.proformaById.including_vat ? 1 : 0
            } else if (this.$route.query.deliveryId) {
              this.model.including_vat = this.deliveryById.including_vat ? 1 : 0
            } else if (this.$route.query.returnCertificateId) {
              this.model.including_vat = this.returnCertificateById.including_vat ? 1 : 0
            } else if (this.$route.query.receiptTaxInvoiceId) {
              this.model.including_vat = this.receiptTaxInvoiceById.including_vat ? 1 : 0
            } else if (this.$route.query.creditInvoiceId) {
              this.model.including_vat = this.creditInvoiceById.including_vat ? 1 : 0
            } else if (this.$route.query.quotationId) {
              this.model.including_vat = this.quotationById.including_vat ? 1 : 0
            }
          }
          if (this.moreDocumentNumber && !this.invoiceId) {
            this.model.doc_series_number = this.model.doc_series_number.id
          }
          this.model.main_number = 18;
          this.model.order_model_number = 15;
          this.model.deliveryNote_model_number = 30;
          this.model.quotation_model_number = 14;

          // if(this.model.data.length > 0) {
            if (this.selectedContact && typeof this.selectedContact === "string") {
              if (this.model.customer_id) {
                let contactData = await this.$store.dispatch('contact/SetContact', {name: this.selectedContact, record_type: 'customers', record_type_number: this.model.customer_id})
                this.model.contact_id = contactData.data.id;
              }
            }
            // for update order status as closed
            if (this.fromOrder) {
              let totalSelectedItemFromOrder = this.selectedProduct.filter(item => item.order_item_id)
              if (this.$route.params.invoice_id || this.$route.params.copiedModel) {
                let orderData = await this.$store.dispatch('order/GetOrderById', this.originModel.order_id);
                let orderStatus;
                if (orderData.orderItems.length !== totalSelectedItemFromOrder.length) {
                  orderStatus = this.setOrderStatus;
                } else {
                  orderStatus = 1;
                }
                if (this.$route.query.type !== 'copy-create') {
                  await this.$store.dispatch('order/EditOrder', {order_id: this.model.order_id, order: {status: orderStatus, main_number: 15, related_number: 14, quotation_id: orderData.quotation_id}}).catch((e) => {
                    this.loading = false;
                    console.log(e)
                  });
                }
              }
            } else if (this.isMultiple) {
              // for update multiple order status 
              if (!this.fromMultipleDelivery && !this.fromMultipleProformaInvoice) {
                // multiple from order
                let orderIds = [];
                this.invoiceItems.forEach(orderItem => {
                  orderIds.push(orderItem.order_id)
                });
                let orderData = await this.$store.dispatch('order/GetOrder', {where_in: 'id|['+orderIds+']'});

                let multipleOrderStatus = [];
                orderData.forEach(data => {
                  this.invoiceItems.forEach(items => {
                    if (data.id == items.order_id) {
                      let totalSelectedItemFromOrder = items.invoiceItems.filter(item => item.order_item_id);
                      if (data.orderItems.length !== totalSelectedItemFromOrder.length) {
                        multipleOrderStatus.push({order_id: data.id, status: 0});
                      } else {
                        multipleOrderStatus.push({order_id: data.id, status: 1});
                      }
                    }
                  })
                });
                this.model.multipleStatus = multipleOrderStatus;
              } else if (this.fromMultipleProformaInvoice) {
                // multiple from proforma
                let orderIds = [];
                this.invoiceItems.forEach(orderItem => {
                  orderIds.push(orderItem.proforma_invoice_id)
                });
                let orderData = await this.$store.dispatch('proforma/GetProforma', {where_in: 'id|['+orderIds+']'});

                let multipleOrderStatus = [];
                orderData.forEach(data => {
                  this.invoiceItems.forEach(items => {
                    if (data.id == items.proforma_invoice_id) {
                      let totalSelectedItemFromOrder = items.invoiceItems.filter(item => item.proforma_invoice_item_id);
                      if (data.proformaInvoiceItems.length !== totalSelectedItemFromOrder.length) {
                        multipleOrderStatus.push({proforma_invoice_id: data.id, status: 0});
                      } else {
                        multipleOrderStatus.push({proforma_invoice_id: data.id, status: 1});
                      }
                    }
                  })
                });
                this.model.multipleStatus = multipleOrderStatus;
              } else if (this.fromMultipleDelivery) {
                // multiple from delivery
                let orderIds = [];
                this.invoiceItems.forEach(orderItem => {
                  orderIds.push(orderItem.delivery_note_id)
                });
                let orderData = await this.$store.dispatch('delivery/GetDelivery', {where_in: 'id|['+orderIds+']'});

                let multipleOrderStatus = [];
                orderData.forEach(data => {
                  this.invoiceItems.forEach(items => {
                    if (data.id == items.delivery_note_id) {
                      let totalSelectedItemFromOrder = items.invoiceItems.filter(item => item.delivery_note_item_id);
                      if (data.deliveryNoteItems.length !== totalSelectedItemFromOrder.length) {
                        multipleOrderStatus.push({delivery_note_id: data.id, status: 0});
                      } else {
                        multipleOrderStatus.push({delivery_note_id: data.id, status: 1});
                      }
                    }
                  })
                });
                this.model.multipleStatus = multipleOrderStatus;
              }
            }
            
            if(this.$route.params.invoice_id) {
              this.isProgressCircular = true;
              // save only update model value using changedFormData from utils
              let saveData = changedFormData(this.model, this.originModel);
              if(saveData.delivery_date==='') delete saveData['delivery_date'];
              if (this.model.order_id) {
                if (this.$route.query.type !== 'copy-create') {
                  saveData.order_id = this.model.order_id;
                }
              } else if (this.model.delivery_note_id) {
                if (this.$route.query.type !== 'copy-create') {
                  saveData.delivery_note_id = this.model.delivery_note_id;
                }
              }
              delete saveData.multiple_delivery;
              delete this.model.multiple_proforma;
              saveData.invoice_date = parseDateYYYYMMDD(this.model.invoice_date)
              data = await this.$store.dispatch('invoice/EditInvoice', {invoice_id: this.$route.params.invoice_id, invoice: saveData}).catch((e) => {
                this.loading = false;
                console.log(e)
              });
              if(data) {
                this.$refs.form.reset();
                this.loading = false;
                await this.onMounteUpdated();
              }
              this.isProgressCircular = false;
            } else {
              this.model.data.forEach((ele) => 
                delete ele.remainingQuantity
              )
              try {
                delete this.model.multiple_delivery;
                delete this.model.multiple_proforma;
                if (this.model.delivery_date === '') delete this.model['delivery_date'];
                data = await this.$store.dispatch('invoice/SetInvoice', this.model);
                if (data) {
                  if (this.model.proforma_invoice_id) {
                    if (this.$route.query.type !== 'copy-create') {
                      if (this.proformaById.status == 0) {
                        await this.$store.dispatch('proforma/EditProforma', {proforma_id: this.model.proforma_invoice_id, proforma: {status: 1, balance: 0}})
                      }
                    }
                  }
                  if (this.fromOrder) {
                    if (!this.$route.params.invoice_id || !this.$route.params.copiedModel) {
                      let totalSelectedItemFromOrder = this.selectedProduct.filter(item => item.order_item_id)
                      let orderStatus;
                      if (this.orderById.orderItems.length !== totalSelectedItemFromOrder.length) {
                        orderStatus = this.setOrderStatus;
                      } else {
                        orderStatus = 1;
                      }
                      if (this.$route.query.type !== 'copy-create') {
                        await this.$store.dispatch('order/EditOrder', {order_id: this.model.order_id, order: {status: orderStatus, main_number: 15, related_number: 14, quotation_id: this.orderById.quotation_id}}).catch((e) => {
                          this.loading = false;
                          console.log(e)
                        });
                      }
                    }
                  }
                }
              } catch (error) {
                this.loading = false;
                console.log(error)
              }
              if(data) {
                this.$refs.form.reset();
                this.loading = false;
                if (this.accessRight.includes('edit')) {
                  await this.$router.push('/invoice/editInvoice/' + data.data.id).catch(()=>{});
                } else {
                  this.callBackFunction();
                }
              }
            }
          // } 
          // else {
          //   this.message = "formRules.productItemAlert";
          //   this.alert = true;
          //   this.loading = false;
          // }
        }
      } 
      // else {
      //   this.message = "formRules.formAlert";
      //   this.alert = true;
      // }
    },
    async deleteItem() {
      this.isProgressCircular = true;
      let obj = this.statusList.find(o => o.name === this.$t('invoice.field.canceled'));
      await this.$store.dispatch('invoice/DeleteInvoiceItemById', this.$route.params.invoice_id)
      this.selectedStatus = obj.name
      await this.$router.push('/invoice/editInvoice/' + this.$route.params.invoice_id).catch(()=>{});
      this.isProgressCircular = false;
    }
  }
}
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
::v-deep .yourRowClass.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>